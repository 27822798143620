import {
  ButtonV2,
  DATE_FORMAT,
  TIME_FORMAT,
  Text,
  Timeline,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { useCallback } from "react";
import { track } from "../../../../../analytics";
import {
  CardWrapper,
  IncompleteProfileCallout,
} from "../../../../../components/Common";
import { QaPublishOptions } from "../../../../../constants";
import {
  TIME_ZONE_VALUE_MAPPING,
  USER_TIMEZONE,
} from "../../../../../constants/timezones";
import { setSnackbar } from "../../../../../store/common";
import { useAppDispatch } from "../../../../../store/hooks";
import { CREATION_STEP_MAPPING } from "../../../constants";
import { useCreateQAData } from "../../../hooks";
import { ReviewEventDetails } from "../../Organisms";

interface IProps {
  isDraft?: boolean;
  goToStep: (step: number) => void;
}

const ReviewDetailsTemplate = ({ isDraft, goToStep }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { companyName, event } = useCreateQAData();

  const {
    title,
    publishType,
    publishDatetime,
    eventStartDatetime,
    submittedAt,
    openDatetime,
    timezone,
    closeDatetime,
    slug,
  } = event;

  // qa preview url on app.say
  const previewUrl = `${window.config.REACT_APP_QA_URL}${slug}`;

  const timezoneObj = TIME_ZONE_VALUE_MAPPING[timezone];
  const timezoneId =
    timezoneObj?.utc?.[0] || USER_TIMEZONE || "America/New_York";

  /**
   * copy preview url to clipboard
   */
  const copyPreviewUrl = () => {
    dispatch(
      setSnackbar({
        message: `URL copied ${previewUrl}.`,
        open: true,
        type: "standard",
        severity: "success",
      })
    );
    window.navigator.clipboard.writeText(previewUrl);
  };

  // dates array to generate the timeline
  const dates = [
    {
      title: "Publish Q&A",
      date:
        publishType === QaPublishOptions.OnceApproved
          ? "Once this Q&A is approved"
          : (publishDatetime as string),
    },
    {
      title: "Open for questions",
      date: openDatetime,
    },
    {
      title: "Close for questions",
      date: closeDatetime,
    },
    {
      title: "Event Starts",
      date: eventStartDatetime,
    },
  ];

  // callbacks for clicking edit icon
  const goToStep0 = useCallback(() => {
    track({
      name: "Q&A Edited from Review",
      client: companyName,
      qaEvent: event.slug,
      qaStatus: event.status,
      composeStep: CREATION_STEP_MAPPING[3],
      reviewEdit: "Q&A Title",
    });
    goToStep(0);
  }, [event]);
  const goToStep1 = useCallback(() => {
    track({
      name: "Q&A Edited from Review",
      client: companyName,
      qaEvent: event.slug,
      qaStatus: event.status,
      composeStep: CREATION_STEP_MAPPING[3],
      reviewEdit: "Timeline",
    });
    goToStep(1);
  }, [event]);

  const formattedSubmittedDate = submittedAt
    ? dayjs(submittedAt)
        .tz(timezoneId)
        .format(`${DATE_FORMAT} [at] ${TIME_FORMAT}`)
    : "";
  return (
    <Stack spacing={8}>
      <IncompleteProfileCallout text="Your company's brand assets are required to be submitted before setting up a Q&A" />
      {!isDraft && (
        <CardWrapper
          cardName="submittedProfile"
          sx={{
            py: 4.5,
            px: 9,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>
              This Q&A was submitted for approval on {formattedSubmittedDate}
            </Text>
            <ButtonV2
              variant="text"
              data-testid="btn-copy"
              onClick={copyPreviewUrl}
            >
              Copy Q&A link
            </ButtonV2>
          </Stack>
        </CardWrapper>
      )}
      <Stack direction="row" spacing={8}>
        <Stack spacing={8} flex={1}>
          <CardWrapper cardName="title" onEdit={goToStep0}>
            <Text variant="subtitle2" mb={3}>
              Q&amp;A Title
            </Text>
            <Text variant="h5">{title}</Text>
          </CardWrapper>
          <CardWrapper cardName="timeline" onEdit={goToStep1}>
            <Stack>
              <Text variant="subtitle2" mb={4}>
                Timeline
              </Text>
              <Timeline dates={dates} timezoneId={timezoneId} />
            </Stack>
          </CardWrapper>
        </Stack>
        <Stack flex={1}>
          <CardWrapper cardName="details">
            <ReviewEventDetails goToStep={goToStep} />
          </CardWrapper>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReviewDetailsTemplate;
