const MaintenanceMode = (): JSX.Element => {
  return (
    <div
      className="p-8 pt-24 bg-light-gray min-h-screen bg-no-repeat"
      style={{
        textAlign: "center",
        padding: "20px",
        font: "20px Helvetica, sans-serif",
        color: "#333",
        paddingTop: "150px",
        minWidth: "150px",
      }}
    >
      <title>Site Maintenance</title>
      <article
        style={{
          display: "block",
          textAlign: "left",
          maxWidth: "650px",
          margin: "0 auto",
        }}
      >
        <h1 style={{ fontSize: "50px" }}> We&rsquo;ll be back soon!</h1>
        <div>
          <p>
            Sorry for the inconvenience but we&rsquo;re performing some
            maintenance at the moment. If you need to you can always{" "}
            <a
              style={{ color: "#dc8100", textDecoration: "none" }}
              href="http://saytechnologies.com"
            >
              contact us
            </a>
            , otherwise we&rsquo;ll be back online shortly!
          </p>
        </div>
      </article>
    </div>
  );
};

export default MaintenanceMode;
