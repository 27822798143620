import { useState, useMemo, useCallback } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
// components
import {
  MiniDrawer,
  getIssuerAuthToken,
  removeAuthToken,
} from "@asayinc/component-library";
import {
  SettingsOutlined,
  Settings,
  InsertInvitationOutlined,
  InsertInvitation,
  EmailOutlined,
  Email,
  HelpOutlineOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  HowToVote,
} from "@mui/icons-material";
import { DotIndicator, IconWithDot, SideNavItem } from "./components";
// redux / data
import {
  useGetFeaturesQuery,
  useGetSettingsQuery,
} from "../../../../store/settings";
import { useFeaturesDisabled } from "../../../../hooks/useFeaturesDisabled";
import {
  useGetUserInfoQuery,
  useTrackEventMutation,
} from "../../../../store/consumerUser";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
// utils
import { track } from "../../../../analytics";
import jwt_decode from "jwt-decode";
// types / constants
import {
  UiEventActions,
  UiEventTypes,
} from "../../../../store/consumerUser/types";
import { IUser, IUserFeatures } from "../../../../store/consumerUser/types";
import { IFeatures } from "../../../../store/settings/types";

interface Token {
  first_name: string;
  last_name: string;
  email: string;
}

const pathnameTrackingActions = {
  "/shareholders": UiEventActions.TabClickShareholderNetwork,
  "/messages": UiEventActions.TabClickMessages,
  "/vote": UiEventActions.TabClickVoteMessages,
};
// TODO: Remove this when vote solicitation goes live
interface IProps {
  disableVoting?: boolean;
}

const SideNav = ({ disableVoting = true }: IProps): JSX.Element => {
  const [sideNavOpen, setSideNavOpen] = useState(true);

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const authToken = getIssuerAuthToken();
  const decodedToken = useMemo(() => {
    if (!authToken) return null;
    try {
      return jwt_decode<Token>(authToken);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [authToken]);

  const { email, first_name } = decodedToken || {};
  const { data, isLoading, isError, error } = useGetSettingsQuery(
    decodedToken ? undefined : skipToken
  );
  const { isSingleFeature } = useFeaturesDisabled();
  const { data: featuresData = {} } = useGetFeaturesQuery(
    decodedToken ? undefined : skipToken
  );
  const { data: userData = {} } = useGetUserInfoQuery(
    decodedToken ? undefined : skipToken
  );
  const { messages, shareholders, voteSolicitation } =
    featuresData as IFeatures;
  const { features: flags = {} } = userData as IUser;
  const { portalMessagesTabUpsell, portalSnTabUpsell, portalVsTabUpsell } =
    flags as IUserFeatures;

  const [trackEvent] = useTrackEventMutation();
  const itemAction = (pathname: string | number | boolean) => {
    const trackingAction = pathnameTrackingActions[pathname as string];
    // if there's a tracking action for the tab make tracking request
    if (trackingAction) {
      trackEvent({
        action: trackingAction,
        type: UiEventTypes.Click,
        sourceUrl: window.location.href,
        targetUrl: `${window.location.origin}${pathname}`,
      });
    }

    if (pathname === "mail") {
      window.open(
        `mailto:support@saytechnologies.com?subject=${companyName} - Issuer Portal Support`
      );
    } else {
      navigate(pathname as string);
    }
  };

  const items = [
    {
      value: "/shareholders",
      active: location.pathname.startsWith("/shareholders"),
      icon: (
        <IconWithDot
          icon={<PeopleAltOutlined />}
          showDot={portalSnTabUpsell && !shareholders && !isSingleFeature}
        />
      ),
      activeIcon: <PeopleAlt />,
      text: (
        <SideNavItem
          label="Shareholders"
          showPill={isSingleFeature && portalSnTabUpsell && !shareholders}
        />
      ),
    },
    {
      value: "/messages",
      active: location.pathname.startsWith("/messages"),
      icon: (
        <IconWithDot
          icon={<EmailOutlined />}
          showDot={portalMessagesTabUpsell && !messages && !isSingleFeature}
        />
      ),
      activeIcon: <Email />,
      text: (
        <SideNavItem
          label="Messages"
          showPill={isSingleFeature && portalMessagesTabUpsell && !messages}
        />
      ),
    },
    {
      value: "/qa",
      active: location.pathname.startsWith("/qa"),
      icon: <InsertInvitationOutlined />,
      activeIcon: <InsertInvitation />,
      text: "Q&A",
    },
    ...(disableVoting
      ? []
      : [
          {
            value: "/vote",
            active: location.pathname.startsWith("/vote"),
            icon: (
              <IconWithDot
                icon={<HowToVote />}
                showDot={
                  portalVsTabUpsell && !voteSolicitation && !isSingleFeature
                }
              />
            ),
            activeIcon: <HowToVote />,
            text: (
              <SideNavItem
                label="Voting"
                showPill={
                  isSingleFeature && portalVsTabUpsell && !voteSolicitation
                }
              />
            ),
          },
        ]),
  ];

  const footerItems = [
    {
      value: "/settings",
      active: location.pathname === "/settings",
      icon: <SettingsOutlined />,
      activeIcon: <Settings />,
      text: "Settings",
      node: !isLoading && !data?.completed ? <DotIndicator /> : undefined,
    },
    {
      value: "mail",
      active: false,
      icon: <HelpOutlineOutlined />,
      text: "Support",
    },
  ];

  const companyName = data?.name || "Unknown Company";

  // use root page logic to navigate user on logo click
  const onLogoClick = useCallback(() => {
    navigate("/");
  }, []);

  const logOutClick = () => {
    track({ name: "Logout Pressed" });
    window.localStorage.clear();
    removeAuthToken();
    navigate("/login");
  };

  let profileProps;
  if (decodedToken) {
    profileProps = {
      logoutAction: logOutClick,
      name: first_name || "",
      email: email || "",
      companyLogo: data?.logo,
      companyTicker: data?.securities?.[0]?.symbol,
      companyName,
    };
  }

  // if this api fails with 403 or 401 user is unauthorized
  if (isError && error) {
    if (
      (error as FetchBaseQueryError).status === 403 ||
      (error as FetchBaseQueryError).status === 401
    ) {
      removeAuthToken();
      return <Navigate to={"/login"} replace />;
    }
  }

  return (
    <MiniDrawer
      showSayLogo
      toggleExpand={toggleSideNav}
      onClickItem={itemAction}
      onLogoClick={onLogoClick}
      open={sideNavOpen}
      onClose={toggleSideNav}
      sx={{ height: "100vh", position: "sticky", top: "0" }}
      listActiveBg
      items={items}
      footerItems={footerItems}
      profileProps={profileProps}
    />
  );
};

export default SideNav;
