import { IProxyEvent } from "./types";
import { rootApi } from "../rootApi";

export const proxyEventsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllProxyEvents: builder.query<IProxyEvent[], boolean>({
      query: (active) => {
        const activeQuery = "&active=true&details=true";
        return `proxy-events/?is_vote=true&approved_only=true${
          active ? activeQuery : ""
        }`;
      },
    }),
  }),
});

export const { useGetAllProxyEventsQuery } = proxyEventsApi;
