import {
  ButtonV2 as Button,
  IButtonAction,
  Text,
} from "@asayinc/component-library";
import { Box, Stack, SxProps } from "@mui/material";
// rtk query
import { useGetFeaturesQuery } from "../../../../store/settings";
// init state
import { INIT_FEATURES_STATE } from "../../../../store/settings";

interface IProps {
  page: "messages" | "voteSolicitation" | "shareholders";
  bgcolor: string;
  header: string;
  description: string;
  buttons: IButtonAction[];
  sx?: SxProps;
  img: string;
}

const IntroBanner = ({
  page,
  bgcolor,
  header,
  description,
  buttons,
  img,
  sx = {},
}: IProps) => {
  const { data = INIT_FEATURES_STATE, isSuccess } = useGetFeaturesQuery();

  const showIntroBanner = !data[page];
  // dont show banner if features are not loaded
  if (!showIntroBanner || !isSuccess) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={24}
      p={8}
      bgcolor={bgcolor}
      borderRadius={2}
      sx={{
        boxShadow:
          "0px 4px 24px 0px rgba(0, 0, 0, 0.02), 0px -4px 40px 0px rgba(0, 0, 0, 0.02)",
        ...sx,
      }}
    >
      <Stack justifyContent="space-between" minHeight={238}>
        <Box>
          <Text variant="h4">{header}</Text>
          <Text variant="body2" mt={4}>
            {description}
          </Text>
        </Box>
        <Stack direction="row" mt={8} spacing={4}>
          {buttons.map(({ label, variant, onClick }) => (
            <Button
              key={label}
              variant={variant}
              onClick={onClick}
              data-testid={label}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Box>
        <img src={img} width={300} height="auto" style={{ display: "block" }} />
      </Box>
    </Stack>
  );
};

export default IntroBanner;
