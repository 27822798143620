import { Box, Stack } from "@mui/material";
import {
  COLORS,
  SayIcon,
  Text,
  Tooltip,
  nFormatter,
} from "@asayinc/component-library";

/**
 * Map sentiment score to color / text
 * 0-25
 * 25.1-45
 * 45.1-54.9
 * 55-74.9
 * 75-100
 */
const mapSentimentToWords = (score?: number) => {
  // handle loading state
  if (typeof score === "undefined") {
    return { text: undefined, color: undefined };
  }

  if (score <= 25) {
    return { text: "Negative", color: COLORS.SATURATED_RED };
  } else if (score <= 45) {
    return { text: "Slightly negative", color: COLORS.SATURATED_RED };
  } else if (score <= 54.99) {
    return { text: "Neutral", color: COLORS.SATURATED_ORANGE };
  } else if (score <= 74.99) {
    return { text: "Slightly positive", color: COLORS.SATURATED_GREEN };
  }
  return { text: "Positive", color: COLORS.SATURATED_GREEN };
};

interface IProps {
  sentiment?: number;
  includeIcon?: boolean;
}

const Sentiment = ({ sentiment, includeIcon = false }: IProps) => {
  const { text, color } = mapSentimentToWords(sentiment);

  return (
    <Tooltip
      wrap={false}
      title={
        text
          ? `The AI-generated sentiment score is ${text.toLowerCase()} (${nFormatter(
              sentiment as number
            )}), based on the tone of the questions asked about this topic.`
          : ""
      }
    >
      <Stack direction="row" component="span">
        {includeIcon && color && (
          <Box mr={1} height="20px">
            <SayIcon size="14px" name="Sparkles" color={color} />
          </Box>
        )}
        <Text
          variant="subtitle2"
          sx={{ color }}
          component="span"
          loadingProps={{ sx: { width: "140px" } }}
        >
          {text}
        </Text>
      </Stack>
    </Tooltip>
  );
};

export default Sentiment;
