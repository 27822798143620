// types
import { IAllShareholderStats, IAllShareholderLocationStat } from "./types";
import { rootApi } from "../rootApi";

interface IStatParams {
  aggregators: string;
  ordering?: string;
}

// Shareholder Lists Api
export const allShareholderStatsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET Generaal Stats
     * */
    getAllShareholderStats: builder.query<IAllShareholderStats, IStatParams>({
      query: ({ aggregators }) => ({
        url: `shareholder-network/stats/?aggregators=${aggregators}`,
        method: "POST",
      }),
    }),
    /**
     * GET Location Stats
     * */
    getAllShareholderLocationStats: builder.query<
      IAllShareholderLocationStat[],
      void
    >({
      query: () => `shareholder-network/locations/`,
    }),
  }),
});

export const {
  useGetAllShareholderStatsQuery,
  useGetAllShareholderLocationStatsQuery,
} = allShareholderStatsApi;
