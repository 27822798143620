import { DotProps } from "recharts";
import { IGraphDotStats } from "../../../../Templates/ProxyDetailGraphCard/hooks/useGraphData";
import { IGraphData } from "../../ProxyDetailGraph";

interface IActiveDot extends DotProps {
  graphMailingStats?: IGraphDotStats;
  payload?: IGraphData;
  value?: number;
}

const ActiveDot = (props: IActiveDot) => {
  const { cx, cy, fill, graphMailingStats, value } = props;

  // dont display dot if there is already a custom dot on this location
  if (
    typeof value === "undefined" ||
    (props?.payload?.name && graphMailingStats?.[props?.payload?.name]?.type)
  ) {
    return null;
  }
  return (
    <circle data-testid="active-dot-circle" cx={cx} cy={cy} r="4" fill={fill} />
  );
};

export default ActiveDot;
