import { HookSwitch, Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack, Link as MuiLink } from "@mui/material";
import { useFormContext } from "react-hook-form";
import {
  CardWrapper,
  FormImageUpload,
  FormSectionDivider,
} from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
  enabled?: boolean;
}

const QAFeature = ({ isLoading, enabled }: IProps) => {
  const { setValue, register, setError } = useFormContext();
  return (
    <CardWrapper cardName="qa-feature" className="settingsSection" id="qa">
      <Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Q&amp;A
        </Text>
        <Text variant="body2">
          Shareholder Q&A allows companies to identify and answer the most
          supported questions from shareholders leading up to their company
          events.
        </Text>

        <Stack direction="row" alignItems="center" my={6}>
          <HookSwitch
            disableTypography
            label=""
            data-testid="qAndA"
            disabled
            name="features.qAndA"
            sx={{
              mr: 0,
            }}
          />
          <Text variant="subtitle2">{enabled ? "Enabled" : "Disabled"}</Text>
        </Stack>
        {enabled && (
          <>
            <FormSectionDivider />
            <Stack mt={6}>
              <Stack direction="row" alignItems="center" spacing={2.5} mb={2}>
                <Text variant="subtitle1">Banner image</Text>
                <TooltipInfoIcon
                  title="Your preview images should contain your company&#39;s
                      wordmark and may contain additional patterns that
                      represent your corporate brand."
                  sx={{
                    height: 20,
                    ".MuiIconButton-sizeMedium": {
                      mt: "-6px",
                    },
                  }}
                  iconColor="black"
                  tooltipColor="black"
                  placement="right"
                />
              </Stack>
              <Text variant="body2" mb={4}>
                Help shareholders identify your company while they are browsing
                Shareholder Q&As. You will see this image on{" "}
                <MuiLink
                  color="primary.main"
                  sx={{ fontWeight: 600 }}
                  href={window.config.REACT_APP_QA_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  app.saytechnologies.com
                </MuiLink>
                .
              </Text>
              <FormImageUpload
                size="large"
                name="qaEventPreviewImage"
                width={900}
                height={590}
                disabled={isLoading}
                description="Use a high quality PNG image: 900x590px"
                fileNameField="qaEventPreviewImageFilename"
                type="company"
                setValue={setValue}
                setError={setError}
                register={register}
              />
            </Stack>
          </>
        )}
      </Stack>
    </CardWrapper>
  );
};

export default QAFeature;
