import { ButtonV2, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  heading: string;
  subheading: string;
  createUrl: string;
}

const NoMessages = ({ heading, subheading, createUrl }: IProps) => {
  const navigate = useNavigate();

  const goToCreateMessage = useCallback(() => {
    navigate(createUrl);
  }, [createUrl]);

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      flex={1}
      pb={10}
    >
      <img
        width={400}
        src={`${window.config.REACT_APP_ASSETS}images/ir-portal/empty_messages.png`}
      />
      <Text variant="subtitle1" sx={{ mb: 3 }}>
        {heading}
      </Text>
      <Text variant="body1" mb={6}>
        {subheading}
      </Text>
      <ButtonV2 data-testid="fab-create-message" onClick={goToCreateMessage}>
        New message
      </ButtonV2>
    </Stack>
  );
};

export default NoMessages;
