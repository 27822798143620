import { Stack } from "@mui/material";
import { CardWrapper, Sentiment } from "../../../../../../../components/Common";
import { COLORS, Text, Tooltip, nFormatter } from "@asayinc/component-library";
import { ITrendingCategory } from "../../../../../../../store/sentiment";

interface IProps extends ITrendingCategory {
  rank: string;
}

const TopicCard = ({
  id,
  rank,
  name,
  sharesOwned,
  bestQuestion,
  sentiment,
}: IProps) => (
  <CardWrapper cardName={`topic-card-${id}`} sx={{ width: "376px" }}>
    <Stack gap={6}>
      <Stack gap={4}>
        <Stack
          sx={{
            backgroundColor: COLORS.P_OUTLINE,
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text variant="subtitle1">{rank}</Text>
        </Stack>
        <Text variant="h5">{name}</Text>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={1}>
          <Text variant="body2" emphasis="medium">
            Shareholders feel
          </Text>
          <Sentiment sentiment={sentiment} includeIcon />
        </Stack>
        <Stack gap={1}>
          <Text variant="body2" emphasis="medium">
            Shares represented
          </Text>
          <Text variant="subtitle2">{nFormatter(sharesOwned)}</Text>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Text variant="body2" emphasis="medium">
          Top question asked:
        </Text>
        <Tooltip title={bestQuestion.length >= 230 ? bestQuestion : ""}>
          <Text variant="body2" sx={{ height: "100px" }}>
            {bestQuestion.length >= 230
              ? bestQuestion.slice(0, 227) + "..."
              : bestQuestion}
          </Text>
        </Tooltip>
      </Stack>
    </Stack>
  </CardWrapper>
);

export default TopicCard;
