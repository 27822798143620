import { IURL_PARAMS } from "../../types/Common";
import { IEventStats } from "../../types/Events";
import { rootApi } from "../rootApi";
import {
  IEventFeedbackResponse,
  IEventSummary,
  IEventSummaryAPIResponse,
} from "./types";

// Event Api
export const eventApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Event summary
     */
    getEventSummary: builder.query<IEventSummary, string>({
      query: (slug) => `qa-events/${slug}/summary/`,
      providesTags: (_response, _err, arg) => [
        "Event",
        { type: "Event", id: arg },
      ],
      transformResponse: (response: IEventSummaryAPIResponse) => {
        const feedback = response.feedback;

        //number of replies total
        const replies = Object.values(feedback).reduce((a, b) => a + b, 0);

        // number of positive feedback
        const positive = feedback.verySatisfied + feedback.satisfied;
        // ensure no division by 0
        // percent of positive feedback
        const csat = positive / (replies || 1);
        // percent of neutral or positive feedback
        const neutralPlus = (positive + feedback.neutral) / (replies || 1);

        return {
          ...response,
          csat,
          replies,
          neutralPlus,
        };
      },
    }),
    /**
     * Event summary
     */
    getEventFeedback: builder.query<
      IEventFeedbackResponse,
      {
        eventSlug: string;
        params: IURL_PARAMS;
      }
    >({
      query: ({ eventSlug, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return `qa-events/${eventSlug}/feedback/?${queryString}`;
      },
    }),
    /**
     * Export a Feedback stats
     */
    exportFeedbackData: builder.mutation<{ message: string }, string>({
      queryFn: async (eventSlug, _api, _extraOptions, baseQuery) => {
        try {
          const result = await baseQuery({
            url: `qa-events/${eventSlug}/feedback/?format=csv`,
            headers: {
              Accept: "text/csv",
            },
            responseHandler: (response) => response.text(),
          });
          if (result.error) {
            throw new Error();
          }

          const blob = new Blob([result.data as BlobPart], {
            type: "application/octet-stream",
          });
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `feedback-${eventSlug}.csv`);
          link.setAttribute("data-testid", "csv-download-link");
          document.body.appendChild(link);
          // cant run in jest
          /* istanbul ignore next */
          if (href) {
            link.click();
          }
          return {
            data: {
              message: `Feedback for event ${eventSlug} exported!`,
            },
          };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: "Failed to parse CSV data" },
            },
          };
        }
      },
    }),
    // STATS
    getEventStats: builder.query<IEventStats, string>({
      query: (slug) => `qa-events/${slug}/stat-trends/`,
    }),
  }),
});

export const {
  useGetEventFeedbackQuery,
  useGetEventSummaryQuery,
  useGetEventStatsQuery,
  useExportFeedbackDataMutation,
} = eventApi;
