import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ICategory } from "./types";

// Define a question categories Api
export const questionCategoriesApi = createApi({
  reducerPath: "questionCategories",
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.config.REACT_APP_CONSUMER_API}v3/`,
  }),
  endpoints: (builder) => ({
    /**
     * Get All Question Category Options for a given Event
     */
    getQuestionCategories: builder.query<ICategory[], string>({
      query: (eventSlug) => `qa-events/${eventSlug}/categories/?specific=True`,
    }),
  }),
});

export const { useGetQuestionCategoriesQuery } = questionCategoriesApi;
