import { useNavigate } from "react-router-dom";
import { Stack, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ButtonV2,
  COLORS,
  useScreenSize,
  IconButton,
  ActionablePopover,
  IButtonAction,
} from "@asayinc/component-library";
import { useCallback, useState } from "react";
import { useMessageDialogHooks, useRequestMoreInfo } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { MessageStep } from "../../../../types/Messages";
import { ExpandWithSayDialog } from "../../../Common";
// redux / store
import { useGetFeaturesQuery } from "../../../../store/settings";
import { UiEventActions } from "../../../../store/consumerUser/types";

interface IProps {
  cancel: () => void;
  previous: () => void;
  next: () => void;
  save: () => void;
  disableSave: boolean;
  isMessageLoadingOrUpdating?: boolean;
  disableNext: boolean;
  currentStepName: MessageStep;
  currentStep: number;
  isCreate: boolean;
}

const Footer = ({
  cancel,
  previous,
  isMessageLoadingOrUpdating,
  next,
  save,
  disableNext,
  disableSave,
  currentStepName,
  currentStep,
  isCreate,
}: IProps): JSX.Element | null => {
  const [expandOpen, setExpandOpen] = useState(false);
  const { messageId, proxyEventId } = useParams() as {
    messageId: string;
    proxyEventId?: string;
  };

  const { testEmail, scheduleSend, openDeleteDialog } = useMessageDialogHooks(
    messageId,
    proxyEventId
  );

  const { handleSubmit } = useFormContext();

  // BEGIN EXPAND DIALOG //
  // open expand with say dialog
  const openExpandDialog = useCallback(() => {
    setExpandOpen(true);
  }, []);
  // close expand dialog
  const closeExpandDialog = useCallback(() => {
    setExpandOpen(false);
  }, []);

  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: proxyEventId
      ? UiEventActions.RequestInfoVoteMessages
      : UiEventActions.RequestInfoMessages,
    callback: closeExpandDialog,
  });

  // avoid passing event data to save function
  const saveMessage = useCallback(() => {
    save();
  }, [save]);

  const navigate = useNavigate();
  const buttonActions = [
    {
      label: "Request more info",
      onClick: requestMoreInfo,
      variant: "primary",
    },
    {
      label: "View draft",
      onClick: () => {
        closeExpandDialog(),
          navigate(
            proxyEventId ? `/vote/${proxyEventId}/messages` : "/messages"
          );
      },
      variant: "secondary",
    },
  ] as IButtonAction[];

  // END EXPAND DIALOG //

  const { data: features, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const isProxyMessage = proxyEventId?.length;
  const isFeatureEnabled = isProxyMessage
    ? features?.voteSolicitation
    : features?.messages;

  const handleTestEmailButton = useCallback(() => {
    if (isFeatureEnabled) {
      testEmail();
    } else {
      openExpandDialog();
    }
  }, [isFeatureEnabled, testEmail]);

  const handleSubmitButton = useCallback(() => {
    if (isFeatureEnabled) {
      scheduleSend();
    } else {
      openExpandDialog();
    }
  }, [isFeatureEnabled, scheduleSend]);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const { isMobile } = useScreenSize();

  // actions for popover
  const popoverActions = [
    {
      onClick: openDeleteDialog,
      testid: "menu-delete-btn",
      text: "Delete",
    },
    ...(isMobile && currentStepName === MessageStep.Review
      ? [
          {
            onClick: handleTestEmailButton,
            testid: "menu-test-btn",
            text: "Test email",
          },
        ]
      : []),
  ];

  const popOverButton = (
    <Box>
      <IconButton
        data-testid="footer-popover-button"
        onClick={toggleAnchorEl}
        variant="ctaAlt"
      >
        <MoreVertIcon />
      </IconButton>
    </Box>
  );

  return (
    <>
      <Stack
        position="sticky"
        bottom={0}
        right={0}
        p={10}
        pb={0}
        pt={0}
        width="100%"
        maxWidth={1440}
        m="0 auto"
        bgcolor="#F0F0F0"
        zIndex={10}
      >
        <Box px={1.5}>
          <Stack
            direction="row"
            sx={{
              borderTop: `1px solid ${COLORS.P_OUTLINE}`,
              pt: 6,
              pb: 10,
            }}
          >
            {currentStep > 0 && (
              <ButtonV2
                variant="secondary"
                disabled={isMessageLoadingOrUpdating}
                data-testid="btn-prev-step"
                onClick={previous}
              >
                Previous
              </ButtonV2>
            )}
            {currentStepName !== MessageStep.Review ? (
              <Stack ml="auto" direction="row" gap={3}>
                {isCreate ? (
                  <ButtonV2
                    variant="secondary"
                    data-testid="btn-cancel"
                    onClick={cancel}
                  >
                    Cancel
                  </ButtonV2>
                ) : (
                  popOverButton
                )}
                <ButtonV2
                  variant="secondary"
                  data-testid="btn-save-message"
                  disabled={disableSave}
                  onClick={handleSubmit(saveMessage)}
                >
                  Save
                </ButtonV2>
                <ButtonV2
                  data-testid="btn-next-step"
                  disabled={disableNext}
                  onClick={handleSubmit(next)}
                >
                  Next
                </ButtonV2>
              </Stack>
            ) : (
              <Stack ml="auto" direction="row" gap={3}>
                {popOverButton}
                {!isMobile && (
                  <ButtonV2
                    variant="secondary"
                    data-testid="btn-testemail-message"
                    onClick={handleTestEmailButton}
                    disabled={isFeaturesLoading}
                  >
                    Test Email
                  </ButtonV2>
                )}
                <ButtonV2
                  data-testid="btn-submit-for-review"
                  onClick={handleSubmitButton} // tbd logic
                  disabled={isFeaturesLoading}
                >
                  Submit for review
                </ButtonV2>
              </Stack>
            )}
          </Stack>
        </Box>
        <ActionablePopover
          anchorEl={anchorEl}
          handleClose={handleClose}
          actions={popoverActions}
        />
      </Stack>
      <ExpandWithSayDialog
        img={`${window.config.REACT_APP_ASSETS}images/ir-portal/ip/${
          proxyEventId
            ? "vote_dialog_illustration.svg"
            : "messages_dialog_illustration.svg"
        }`}
        imgAlt="Expand with Say Messages"
        title="Expand with Say"
        description="Expand the features you have on Say to send this message to your shareholders' inbox. Any message you create here will be saved as a draft. Not ready to upgrade yet? Don't worry, your message is saved as draft. "
        open={expandOpen}
        closeDialog={closeExpandDialog}
        buttonActions={buttonActions}
        testId="messages"
      />
    </>
  );
};

export default Footer;
