import { COLORS, SingleBarChart, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { IProxyProposal } from "../../../../../../../../../store/proxyEvent/types";
import { useGetProxyEventDetailsQuery } from "../../../../../../../../../store/proxyEvent";
import { useParams } from "react-router-dom";

const COLOR_CHOICES = [
  COLORS.DATA_VIZ_DARK_GREEN,
  COLORS.DATA_VIZ_PINK,
  COLORS.DATA_VIZ_BLUE,
  COLORS.DATA_VIZ_GREY,
  COLORS.DATA_VIZ_LIGHT_GREEN,
];

const COLOR_MAPPING = {
  for: COLORS.DATA_VIZ_DARK_GREEN,
  against: COLORS.DATA_VIZ_PINK,
  withheld: COLORS.DATA_VIZ_GREY,
  abstain: COLORS.DATA_VIZ_GREY,
};

interface IProps extends Omit<IProxyProposal, "title"> {
  title?: string;
  multi?: boolean; // is this a multi proposal section
  last?: boolean; // is last item in a multi proposal section
  isLoading?: boolean;
}
interface ILoadingProps {
  isLoading: true;
}

const Proposal = ({ isLoading, ...props }: IProps | ILoadingProps) => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data } = useGetProxyEventDetailsQuery(proxyEventId);
  // use grey if no votes yet
  const hasVotes = !!data?.proxyEventStats?.shareholdersVoted;
  // loading skeleton
  if (isLoading) {
    return (
      <Box>
        <Text width={112} mb={3} />
        <Text width={1} mb={6} />
        <Text width={1} mb={6} />
        <Text width={1} mb={6} />
      </Box>
    );
  }
  const { title, details, multi, last, proposalChoiceStats, id } =
    props as IProps;
  // use predefined color mapping if choice is in mapping, otherwise get next
  // available color. Both scenarios, remove color from options
  const generateOptions = () => {
    let colorChoices = COLOR_CHOICES;
    const getColor = (choice: string) => {
      const color =
        COLOR_CHOICES.indexOf(COLOR_MAPPING?.[choice]) !== -1
          ? COLOR_MAPPING[choice]
          : colorChoices[0];
      colorChoices = colorChoices.filter((c) => c !== color);
      return color;
    };

    // sort choices to always place "for" first and "abstain" last
    const sortedChoiceStats = [...proposalChoiceStats].sort((a, b) =>
      a.choice === "for" || b.choice === "abstain" ? -1 : 0
    );
    return sortedChoiceStats.map((choice) => ({
      value: choice.voteCount,
      label: choice.choice.toLowerCase(),
      color: hasVotes ? getColor(choice.choice) : COLORS.DATA_VIZ_GREY,
    }));
  };

  const options = generateOptions();
  const hasBorder = !last && multi;
  return (
    <Box data-testid={`proposal-${id}`}>
      {title && (
        <Text variant="subtitle2" mb={multi ? 6 : 3}>
          {title}
        </Text>
      )}
      <Text variant={multi ? "subtitle2" : "body2"} mb={6}>
        {details}
      </Text>
      <Box>
        <SingleBarChart options={options} />
      </Box>
      {hasBorder && (
        <Box width="100%" bgcolor={COLORS.P_OUTLINE} height="1px" my={6} />
      )}
    </Box>
  );
};

export default Proposal;
