import { useParams } from "react-router-dom";
// components
import { Stack } from "@mui/material";
import { RequestQuote, Email, HowToVote, BarChart } from "@mui/icons-material";
import { nFormatter, useScreenSize } from "@asayinc/component-library";
import { StatCard } from "../../../../../../../../../components/Common";
// data / redux
import { useGetProxyMessageStatsQuery } from "../../../../../../../../../store/proxyEvent";
// utils
import { formatAsPercentage } from "../../../../../../../../../utils";
// types
import { IProxyMessageStats } from "../../../../../../../../../store/proxyEvent/types";

const minWidth = "";
const width = "calc(50% - 16px)";

const MessageStats = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data = {}, isFetching } = useGetProxyMessageStatsQuery(proxyEventId);
  const { isMedium } = useScreenSize();

  const {
    totalMessagesSent,
    totalMessagesSending,
    totalReachedShareholders,
    totalReachedShares,
    totalVotedShareholders,
    totalVotedShares,
    gainedPct,
  } = data as IProxyMessageStats;

  return (
    <Stack direction={!isMedium ? "column" : "row"} spacing={8} mb={12}>
      <Stack direction="row" spacing={8} width="100%">
        <StatCard
          icon={<Email />}
          isLoading={isFetching}
          title="Sent"
          minWidth={minWidth}
          width={width}
          singleStat={{
            value: totalMessagesSent ? nFormatter(totalMessagesSent) : "0",
            subStat: totalMessagesSending
              ? `${nFormatter(totalMessagesSending)} being sent`
              : "0 being sent",
          }}
        />
        <StatCard
          icon={<RequestQuote />}
          isLoading={isFetching}
          title="Reached"
          minWidth={minWidth}
          width={width}
          singleStat={{
            value: totalReachedShares
              ? nFormatter(totalReachedShares, true)
              : "0",
            subText: "shares",
            subStat: totalReachedShareholders
              ? `${nFormatter(totalReachedShareholders, true)} shareholders`
              : "0 shareholders",
          }}
        />
      </Stack>
      <Stack direction="row" spacing={8} width="100%">
        <StatCard
          icon={<HowToVote />}
          isLoading={isFetching}
          title="Voted"
          minWidth={minWidth}
          width={width}
          singleStat={{
            value: totalVotedShares ? nFormatter(totalVotedShares, true) : "0",
            subText: "shares",
            subStat: totalVotedShareholders
              ? `${nFormatter(totalVotedShareholders, true)} shareholders`
              : "0 shareholders",
          }}
        />
        <StatCard
          icon={<BarChart />}
          isLoading={isFetching}
          title="% of total shares voted"
          minWidth={minWidth}
          width={width}
          singleStat={{
            value: gainedPct ? formatAsPercentage(gainedPct) : "0%",
            subText: `shares voted`,
            subStat: "from vote messages",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default MessageStats;
