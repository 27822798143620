/* istanbul ignore file */

import {
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { refreshTokenIfExpired } from "../api/adapter";
import { Params } from "../types/Common";
import { TEST_WINDOW_CONFIG } from "../test-tools/constants";

// use test configuration if node_env is test
const windowConfig =
  process.env.NODE_ENV === "test" ? TEST_WINDOW_CONFIG : window.config;

// camelizes all response data
export const getBaseQueryWithCamelize = (
  baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    FetchBaseQueryMeta
  >
) => {
  const baseQueryWithCamelize: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions = {}) => {
    // decamelize request fields
    if (typeof args !== "string") {
      args.body = humps.decamelizeKeys(args.body);
    }
    const result = await baseQuery(args, api, extraOptions);
    if (result.data) {
      // camelize response data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.data = humps.camelizeKeys(result.data as any);
    }
    return result;
  };
  return baseQueryWithCamelize;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: `${windowConfig.REACT_APP_CONSUMER_API}v3/portal/`,
  prepareHeaders: async (headers) => {
    const token = await refreshTokenIfExpired();
    headers.set("Authorization", `JWT ${token}`);
    const companyOverride = window.localStorage.getItem("company-override");
    if (companyOverride) headers.set("Company-Override", companyOverride);
    return headers;
  },
});

export const baseQueryWithCamelize = getBaseQueryWithCamelize(baseQuery);

export const stripEmptyParams = (params: Params) =>
  Object.fromEntries(
    Object.entries(params).filter(([, v]) => v != null && v != "")
  );
