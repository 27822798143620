import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseQueryWithCamelize } from "../apiTools";
import { LoginResponse, ResetPassWordPostData } from "./types";
import { TEST_WINDOW_CONFIG } from "../../test-tools/constants";

// use test configuration if node_env is test
const windowConfig =
  process.env.NODE_ENV === "test" ? TEST_WINDOW_CONFIG : window.config;

const baseQuery = getBaseQueryWithCamelize(
  fetchBaseQuery({
    baseUrl: `${windowConfig.REACT_APP_SSO_API}v1/`,
  })
);

export const ssoUserApi = createApi({
  reducerPath: "ssoUserApi",
  baseQuery,
  endpoints: (builder) => ({
    resetPassword: builder.mutation<unknown, ResetPassWordPostData>({
      query: (data) => ({
        url: "auth/password/reset/confirm/",
        method: "POST",
        body: data,
      }),
    }),
    attemptLogin: builder.mutation<
      LoginResponse,
      { email: string; password: string }
    >({
      query: (data) => ({
        url: "auth/login/",
        method: "POST",
        body: data,
      }),
    }),
    submitEmail: builder.mutation<unknown, { email: string; domain: string }>({
      query: ({ domain, ...data }) => ({
        url: `auth/password/reset/?s=${domain}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useAttemptLoginMutation,
  useSubmitEmailMutation,
} = ssoUserApi;
