import { Card, COLORS, Text, nFormatter } from "@asayinc/component-library";
import { Box, Popover, Stack } from "@mui/material";
import { IActiveDotState } from "../../ProxyDetailGraph";

interface IProps {
  open: boolean;
  handleClose: () => void;
  activeMailing: IActiveDotState | null;
  sharesMax: number;
  shareholderMax: number;
}

const MailingPopover = ({
  open,
  handleClose,
  activeMailing,
  sharesMax,
  shareholderMax,
}: IProps) => {
  return (
    <Popover
      id={"test-id"}
      open={open}
      anchorEl={activeMailing?.anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        pointerEvents: "none",
      }}
    >
      <Card
        data-testid="graph-tooltip"
        elevation={8}
        sx={{ px: 4, py: 3, borderRadius: 2 }}
      >
        {activeMailing && (
          <>
            <Text variant="subtitle3" emphasis="medium_high" mb={0.5}>
              {activeMailing.name}
            </Text>
            <Text variant="body3" emphasis="medium" mb={1.5}>
              Total {activeMailing.range}:
            </Text>
            <Stack direction="row" alignItems="center" mb={1}>
              <Box
                bgcolor={COLORS.DATA_VIZ_DARK_GREEN}
                width={8}
                height={8}
                borderRadius="100%"
                data-testid="activeMailing-stats"
                mr={2}
              />
              <Text variant="body3">
                {nFormatter(activeMailing.shares || 0)} shares (
                {Math.round((activeMailing.shares / sharesMax) * 100 * 100) /
                  100}
                %)
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box
                bgcolor={COLORS.DATA_VIZ_LIGHT_GREEN}
                width={8}
                height={8}
                borderRadius="100%"
                mr={2}
              />
              <Text variant="body3">
                {nFormatter(activeMailing.shareholders || 0)} shareholders (
                {Math.round(
                  (activeMailing.shareholders / shareholderMax) * 100 * 100
                ) / 100}
                %)
              </Text>
            </Stack>
          </>
        )}
      </Card>
    </Popover>
  );
};

export default MailingPopover;
