import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";

const FourOFour = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      minHeight="100vh"
    >
      <img
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "400px",
        }}
        src={`${window.config.REACT_APP_ASSETS}images/shared-site-assets/svgs/404.svg`}
        alt="404"
      />
      <Box mt={6} px={6} textAlign="center">
        <Text variant="subtitle1">
          Looks like you&apos;ve wandered into the void. There&apos;s nothing
          here to see.
        </Text>
        <Text mt={3}>
          You may have mistyped the address or the page may have moved.
        </Text>
      </Box>
    </Stack>
  );
};

export default FourOFour;
