import {
  Dialog,
  IButtonAction,
  IconButton,
  Text,
  getIssuerAuthToken,
} from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetUserInfoQuery,
  useTrackEventMutation,
} from "../../../../../store/consumerUser";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  UiEventActions,
  UiEventTypes,
} from "../../../../../store/consumerUser/types";

interface IProps {
  img: string;
  url: string;
  imgAlt: string;
  title: string;
  description: string;
  fieldName: string;
  conditional?: boolean;
  action: UiEventActions;
}

const FeatureIntroDialog = ({
  img,
  imgAlt,
  url,
  title,
  description,
  conditional = true,
  fieldName,
  action,
}: IProps) => {
  const token = getIssuerAuthToken();
  const { data } = useGetUserInfoQuery(token ? undefined : skipToken);
  const [trackEvent] = useTrackEventMutation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // after api returns update state to open dialog
  useEffect(() => {
    if (conditional && data?.features?.[fieldName]) {
      setOpen(true);
    }
  }, [data, conditional]);

  // close dialog immediately and hit api to disable the feature flag for displaying the dialog
  const closeDialog = () => {
    trackEvent({
      action: action,
      type: UiEventTypes.Click,
      sourceUrl: location.href,
      targetUrl: null,
    });
    setOpen(false);
  };

  const goToLearnMore = () => {
    navigate(url);
    closeDialog();
  };

  const buttonActions = [
    {
      label: "Learn more",
      onClick: goToLearnMore,
      variant: "primary",
    },
  ] as IButtonAction[];

  return (
    <Dialog
      fullBleed
      data-testid={`${fieldName}-intro-dialog`}
      ctaOrientation="horizontal"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      sx={{
        ".MuiDialogActions-root": {
          justifyContent: "center",
        },
      }}
      content={
        <Box textAlign="center" mb={8} maxWidth={662}>
          <IconButton
            aria-label="close"
            sx={{
              right: (theme) => theme.spacing(3),
              top: (theme) => theme.spacing(3),
              position: "absolute",
            }}
            data-testid="dialog-close"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <img src={img} alt={imgAlt} width="100%" height="auto" />
          <Text variant="h4" mb={6} mt={10}>
            {title}
          </Text>
          <Text variant="body1" maxWidth="546px" margin="0 auto">
            {description}
          </Text>
        </Box>
      }
      open={open}
    />
  );
};

export default FeatureIntroDialog;
