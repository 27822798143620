import { Stack } from "@mui/material";
import { useEffect, useMemo } from "react";
import { IntroBanner, ListHeader } from "../../../../components/Common";
import { ShareholderStats } from "./components";
import { ShareholdersTable } from "./components";
import { CollapsingAlert } from "../../../../components/Messages";
import { useRequestMoreInfo } from "../../../../hooks";
import { UiEventActions } from "../../../../store/consumerUser/types";
import { useGetAllProxyEventsQuery } from "../../../../store/proxyEvents";
import { IProxyEvent } from "../../../../store/proxyEvents/types";
import dayjs from "dayjs";
import { COLORS, DATE_FORMAT } from "@asayinc/component-library";
import { useGetFeaturesQuery } from "../../../../store/settings";

const AllShareholders = () => {
  useEffect(() => {
    document.title = "Shareholders | Say Issuer Portal";
  }, []);

  const { data: features, isSuccess: isFeaturesSuccess } =
    useGetFeaturesQuery();

  const { data: events = [] as IProxyEvent[] } =
    useGetAllProxyEventsQuery(false);

  // api returns active as well as past events, return only events with a meeting.meetingDate in the past using dayjs
  const proxyEventDate = useMemo(
    () =>
      events.filter((event) =>
        dayjs(event.meeting.meetingDate).isBefore(dayjs())
      )?.[0]?.recordDate,
    [events]
  );

  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoShareholderNetwork,
  });

  const alertActions = [
    {
      label: "Request fresh data",
      onClick: requestMoreInfo,
    },
  ];

  return (
    <Stack
      px={10}
      pb={10}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      data-testid="all-shareholders-page"
    >
      <ListHeader title="Shareholders" sx={{ pb: 8 }} />

      {!features?.shareholders && isFeaturesSuccess && !!proxyEventDate && (
        <CollapsingAlert
          showAlert={true}
          actions={alertActions}
          showNotificationIcon={false}
          defaultCloseIcon={false}
          message={`Below is a snapshot of your shareholders, as of your last proxy event on ${dayjs(
            proxyEventDate
          ).format(
            DATE_FORMAT
          )}. To get the latest data on your shareholders, request more info.`}
          sx={{
            width: "100%",
            mb: 8,
            px: "32px !important",
          }}
        />
      )}
      <IntroBanner
        page="shareholders"
        bgcolor={COLORS.LIGHT_PINK}
        img={`${window.config.REACT_APP_ASSETS}images/ir-portal/ip/shareholders_banner_illustration.svg`}
        header="Get to know your shareholders"
        description="Shareholder network offers insights about your top shareholders to help you better tell your company's story. Request more information to gain access to daily updates on your shareholders."
        buttons={[
          {
            label: "Request more info",
            variant: "primary",
            onClick: requestMoreInfo,
          },
        ]}
        sx={{ mb: 8 }}
      />
      <ShareholderStats />
      <ShareholdersTable />
    </Stack>
  );
};

export default AllShareholders;
