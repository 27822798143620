import { Navigate } from "react-router-dom";
import { getIssuerAuthToken, Loader } from "@asayinc/component-library";
import { useGetFeaturesQuery } from "../../store/settings";
import { PageCategories } from "../../constants";

// TODO: Remove this when vote solicitation goes live
interface IProps {
  disableVoting?: boolean;
}

const HomePage = ({ disableVoting = true }: IProps): JSX.Element => {
  const authToken = getIssuerAuthToken();

  const { data, isSuccess: isFeaturesSuccess } = useGetFeaturesQuery(
    undefined,
    { skip: !authToken }
  );

  const lastPageCategory = window.localStorage.getItem("last-page-category");
  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  // default homepage priority
  const returnDefaultHomePage = () => {
    if (data?.voteSolicitation && !disableVoting) {
      return <Navigate to="/vote" replace />;
    } else if (data?.messages) {
      return <Navigate to="/messages" replace />;
    } else if (data?.qAndA) {
      return <Navigate to="/qa" replace />;
    }
    return <Navigate to="/settings" replace />;
  };
  if (isFeaturesSuccess) {
    if (!lastPageCategory) {
      return returnDefaultHomePage();
    } else if (data?.qAndA && lastPageCategory === PageCategories.QA) {
      return <Navigate to="/qa" replace />;
    } else if (data?.messages && lastPageCategory === PageCategories.MESSAGES) {
      return <Navigate to="/messages" replace />;
    } else if (
      !disableVoting &&
      data?.voteSolicitation &&
      lastPageCategory === PageCategories.PROXY
    ) {
      return <Navigate to="/vote" replace />;
    } else if (lastPageCategory === PageCategories.SETTINGS) {
      return <Navigate to="/settings" replace />;
    } else {
      return returnDefaultHomePage();
    }
  }

  return (
    <>
      <Loader full />
    </>
  );
};

export default HomePage;
