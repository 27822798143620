import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  Person,
  BarChart,
  RequestQuote,
  LocationOn,
} from "@mui/icons-material";
import { StatCard } from "../../../../../../../components/Common";
import {
  useGetAllShareholderLocationStatsQuery,
  useGetAllShareholderStatsQuery,
} from "../../../../../../../store/allShareholderStats";
import LocationDialog from "../LocationDialog";
import { parseLocationData } from "./parseLocationData";
import { nFormatter, Text } from "@asayinc/component-library";
import {
  INIT_FEATURES_STATE,
  useGetFeaturesQuery,
} from "../../../../../../../store/settings";
import { disabledFeatureData } from "./constants";

const ShareholderStats = () => {
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  // get feature data
  const { data: features = INIT_FEATURES_STATE, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const { messages: isFeatureEnabled } = features;

  // get stat data
  const { data: stats, isFetching } = useGetAllShareholderStatsQuery({
    aggregators: "percentiles,value_count,sum",
  });
  // get location stat data
  const { data: locationData, isFetching: isLocationFetching } =
    useGetAllShareholderLocationStatsQuery(
      !isFeaturesLoading && isFeatureEnabled ? undefined : skipToken
    );

  // close location dialog
  const closeLocationDialog = () => {
    setLocationDialogOpen(false);
  };
  // open location dialog
  const openLocationDialog = () => {
    setLocationDialogOpen(true);
  };

  // parse location stat data to form statcard understands
  const locationStats = useMemo(
    () =>
      isFeatureEnabled
        ? parseLocationData(locationData, stats)
        : disabledFeatureData,
    [locationData, stats, isFeatureEnabled]
  );

  const allApisLoading = isFetching && isLocationFetching;

  const medianCardStat = isFeatureEnabled
    ? {
        value: stats?.percentiles?.values?.["50.0"]
          ? nFormatter(stats.percentiles.values["50.0"])
          : undefined,
        tooltip: stats?.percentiles?.values?.["50.0"]
          ? nFormatter(stats.percentiles.values["50.0"], true)
          : undefined,
      }
    : { value: "25" };

  return (
    <Stack>
      <Text variant="subtitle1" width={allApisLoading ? "320px" : undefined}>
        {allApisLoading ? undefined : "Overview"}
      </Text>
      <Stack direction="row" gap={8} pt={6} pb={8} overflow="auto">
        <StatCard
          icon={<Person />}
          isLoading={isFetching}
          title="Total shareholders"
          minWidth="170px"
          width="calc(23% - 24px)"
          singleStat={{
            value: stats?.valueCount ? nFormatter(stats.valueCount) : undefined,
            tooltip: stats?.valueCount
              ? nFormatter(stats.valueCount, true)
              : undefined,
          }}
        />
        <StatCard
          icon={<RequestQuote />}
          title="Total shares owned"
          isLoading={isFetching}
          minWidth="170px"
          width="calc(23% - 24px)"
          singleStat={{
            value: stats?.sum ? nFormatter(stats.sum) : undefined,
            tooltip: stats?.sum ? nFormatter(stats.sum, true) : undefined,
          }}
        />
        <StatCard
          icon={<BarChart />}
          title="Median shares owned"
          isLoading={isFetching}
          minWidth="170px"
          width="calc(23% - 24px)"
          singleStat={medianCardStat}
          blurStat={!isFeatureEnabled}
        />
        <StatCard
          icon={<LocationOn />}
          title="Locations"
          width="calc(31% - 24px)"
          minWidth="218px"
          isLoading={isLocationFetching || isFetching}
          multiStats={locationStats.slice(0, 3)}
          openDialog={openLocationDialog}
          blurStat={!isFeatureEnabled}
        />
      </Stack>
      <LocationDialog
        locationStats={locationStats}
        close={closeLocationDialog}
        open={locationDialogOpen}
      />
    </Stack>
  );
};

export default ShareholderStats;
