import { useState } from "react";
import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { IntroBanner, ListHeader } from "../../components/Common";
import { useHasAMessage } from "../../hooks/useHasAMessage";
import { MessageType } from "../../store/messageLists";
import {
  CollapsingAlert,
  FeaturedTemplates,
  NewMessageTemplateDialog,
} from "../../components/Messages";
import { useGetFeaturesQuery } from "../../store/settings";
import { UiEventActions } from "../../store/consumerUser/types";
import { useRequestMoreInfo } from "../../hooks";
import { COLORS } from "@asayinc/component-library";

const Messages = () => {
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const { hasAMessage, loading } = useHasAMessage(MessageType.SM);

  const toggleTemplateDialog = () => {
    setTemplateDialogOpen((t) => !t);
  };

  const closeTemplateDialog = () => setTemplateDialogOpen(false);

  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoMessages,
  });

  const alertActions = [
    {
      label: "Request more info",
      onClick: requestMoreInfo,
    },
  ];

  const { data: features, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const isFeatureEnabled = features?.messages;
  const showDiscoveryBanner =
    !(loading || isFeaturesLoading) && !isFeatureEnabled;

  return (
    <Stack px={10} maxWidth={1440} m="0 auto" height="100%">
      <ListHeader
        title="Messages"
        buttonProps={
          isFeatureEnabled
            ? {
                onClick: toggleTemplateDialog,
                text: "New message",
              }
            : undefined
        }
        sx={{ mb: showDiscoveryBanner ? 0 : 6 }}
      />
      {showDiscoveryBanner &&
        (hasAMessage ? (
          <CollapsingAlert
            showAlert={true}
            actions={alertActions}
            showNotificationIcon={false}
            defaultCloseIcon={false}
            message="Expand your Say feature set to send messages to shareholders."
            sx={{
              width: "100%",
              px: "32px !important",
              mb: 8,
            }}
          />
        ) : (
          <IntroBanner
            page="messages"
            img={`${window.config.REACT_APP_ASSETS}images/ir-portal/ip/messages_banner_illustration.svg`}
            bgcolor={COLORS.LIGHT_LIME}
            header="Shareholder messages, reimagined"
            description="Share your company's story directly with retail shareholders using Messages - let shareholders know about product launches, partnership announcements, events and more, right to their inbox. Don’t worry, you won’t be charged until the message is sent."
            buttons={[
              {
                label: "Try for free",
                variant: "primary",
                onClick: toggleTemplateDialog,
              },
              {
                label: "Request more info",
                variant: "text",
                onClick: requestMoreInfo,
              },
            ]}
            sx={{ mb: 8 }}
          />
        ))}
      <NewMessageTemplateDialog
        open={templateDialogOpen}
        closeDialog={closeTemplateDialog}
      />
      <FeaturedTemplates />
      <Outlet />
    </Stack>
  );
};

export default Messages;
