import { IGraphDotStat } from "../../Templates/ProxyDetailGraphCard/hooks/useGraphData";

interface IProps {
  cx: number;
  cy: number;
  onMouseEnter: (
    e: React.MouseEvent<SVGSVGElement>,
    stats: IGraphDotStat
  ) => void;
  onMouseLeave: () => void;
  dotStats: IGraphDotStat;
}

const LocalPostOfficeDot = ({
  cx,
  cy,
  onMouseEnter,
  onMouseLeave,
  dotStats,
}: IProps) => {
  // pass back this dots stats to the graph to be displayed
  const handleMouseEnter = (e: React.MouseEvent<SVGSVGElement>) => {
    onMouseEnter(e, dotStats);
  };

  return (
    <svg
      x={cx}
      y={cy}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      width="52"
      height="58"
      data-testid="icon-LocalPostOfficeDot"
      viewBox="0 0 52 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_753_78314)">
        <rect x="12" y="12" width="28" height="28" rx="4" fill="white" />
        <path
          d="M20.4001 32.4001C20.1734 32.4001 19.9834 32.3234 19.8301 32.1701C19.6768 32.0168 19.6001 31.8268 19.6001 31.6001V26.8001C19.6001 26.5734 19.6768 26.3834 19.8301 26.2301C19.9834 26.0768 20.1734 26.0001 20.4001 26.0001H22.0001V23.6001C22.0001 22.489 22.389 21.5445 23.1668 20.7668C23.9445 19.989 24.889 19.6001 26.0001 19.6001H28.4001C29.5112 19.6001 30.4557 19.989 31.2334 20.7668C32.0112 21.5445 32.4001 22.489 32.4001 23.6001V32.4001H31.2001V30.8001H28.4001V31.6001C28.4001 31.8268 28.3234 32.0168 28.1701 32.1701C28.0168 32.3234 27.8268 32.4001 27.6001 32.4001H20.4001ZM24.0001 30.1834L27.2001 28.4001V27.2001L24.0001 28.9834L20.8001 27.2001V28.4001L24.0001 30.1834ZM28.4001 29.6001H31.2001V23.6001C31.2001 22.8223 30.9279 22.1612 30.3834 21.6168C29.839 21.0723 29.1779 20.8001 28.4001 20.8001H26.0001C25.2223 20.8001 24.5612 21.0723 24.0168 21.6168C23.4723 22.1612 23.2001 22.8223 23.2001 23.6001V26.0001H27.6001C27.8268 26.0001 28.0168 26.0768 28.1701 26.2301C28.3234 26.3834 28.4001 26.5734 28.4001 26.8001V29.6001ZM25.2001 24.8001V23.6001H29.2001V24.8001H25.2001Z"
          fill="#26896B"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_753_78314"
          x="0"
          y="0"
          width="52"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_753_78314"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_753_78314"
            result="effect2_dropShadow_753_78314"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_753_78314"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LocalPostOfficeDot;
