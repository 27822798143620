import { pluralize } from "@asayinc/component-library";
import { ISentimentResponse, IGetExportOptions } from "./types";
import { IURL_PARAMS } from "../../types/Common";
import { rootApi } from "../rootApi";

// Define a question categories Api
export const sentimentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get All Question Category Options for a given Event
     */
    getSentiment: builder.query<
      ISentimentResponse,
      {
        eventSlug: string;
        params: IURL_PARAMS;
      }
    >({
      query: ({ eventSlug, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return `qa-events/${eventSlug}/sentiment/?${queryString}`;
      },
      transformResponse: (response, _) => {
        const categoriesData = response as ISentimentResponse;
        return {
          ...categoriesData,
          ids: categoriesData.results.map(({ id }) => id),
        };
      },
    }),
    /**
     * Export a set of topics
     */
    exportTopics: builder.mutation<{ message: string }, IGetExportOptions>({
      queryFn: async (
        { eventSlug, topicIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        try {
          const result = await baseQuery({
            url: `qa-events/${eventSlug}/sentiment/?specific_category_ids=${topicIds.join(
              ","
            )}`,
            headers: {
              Accept: "text/csv",
            },
            responseHandler: (response) => response.text(),
          });

          const blob = new Blob([result.data as BlobPart], {
            type: "application/octet-stream",
          });
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `say-topics-${eventSlug}.csv`);
          document.body.appendChild(link);
          link.click();
          return {
            data: {
              message: `${topicIds.length} ${pluralize(
                "topic",
                "topics",
                topicIds.length
              )} exported!`,
            },
          };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: "Failed to parse CSV data" },
            },
          };
        }
      },
    }),
  }),
});

export const { useGetSentimentQuery, useExportTopicsMutation } = sentimentApi;
