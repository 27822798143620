/**
 * set custom breakpoints for kumquats
 */
export const sayCustomBreakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 1000,
      md: 1200,
      lg: 1400, // unused
      xl: 1920, // unused
    },
  },
};

export const BLURRY = {
  textShadow: "0 0 8px #000",
  color: "transparent",
};

export enum PageCategories {
  SETTINGS = "settings",
  QA = "qa",
  MESSAGES = "messages",
  PROXY = "vote_solicitation",
  SHAREHOLDERS = "shareholders",
}

export * from "./filters";
export * from "./events";
export * from "./sort";
export * from "./settings";
export * from "./aggregates";
export * from "./messages";
export * from "./emojis";
