import { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  COLORS,
  nFormatter,
  pluralize,
  Text,
  TooltipInfoIcon,
} from "@asayinc/component-library";
// components
import { useGetFilterStatsQuery } from "../../../../store/messageCompose";
import { RecipientFilters } from "../../Organisms";
import { CardWrapper } from "../../../Common";
// utils
import { parseForm } from "../../../../utils/messages";
// rtk
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetEventFilterOptionsQuery } from "../../../../store/event";
import { useGetShareholderTagsQuery } from "../../../../store/shareholderTags";
import { useGetMessageFilterOptionsQuery } from "../../../../store/messageLists";
// hook form
import { useWatch } from "react-hook-form";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { MessageStep } from "../../../../types/Messages";
import { useParams } from "react-router-dom";
import { useGetFeaturesQuery } from "../../../../store/settings";

interface IProps {
  isLoading?: boolean;
}

const ChooseRecipients = ({ isLoading }: IProps) => {
  const { proxyEventId } = useParams();
  const { data: features, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const { isError: isEventFilterOptionsError } =
    useGetEventFilterOptionsQuery();
  const { isError: isTagsError } = useGetShareholderTagsQuery();
  const { isError: isMessageFilterOptionsError } =
    useGetMessageFilterOptionsQuery();
  const [ready, setReady] = useState(false);
  const query = useWatch({ name: "filterMap.query" });

  // messages feature provides all filtering data, vote does not
  // so if messages is enabled we have enough data for both
  const isFilterDataAvailable = proxyEventId
    ? !!features?.voteSolicitation || !!features?.messages
    : !!features?.messages;

  // the way the form handles values and the way the api needs them is slightly different
  const parsedFormData = useMemo(
    () => parseForm({ filterMap: { query } }, MessageStep.ChooseRecipients),
    [query]
  );
  const {
    data: { numShareholders, sharesRepresented } = {
      numShareholders: 0,
      sharesRepresented: 0,
    },
    isFetching: isStatsLoading,
  } = useGetFilterStatsQuery(
    // dont pull data until after first render to give useWatch a chance to run
    ready
      ? {
          query: JSON.stringify(parsedFormData?.filterMap?.query || {}),
          proxyEventId,
        }
      : skipToken
  );

  // show snackbar if data load fails
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please refresh and try again.",
    isError:
      isEventFilterOptionsError || isTagsError || isMessageFilterOptionsError,
  });

  useEffect(() => {
    setReady(true);
  });

  return (
    <CardWrapper full cardName="chooseRecipients">
      <Stack
        direction="row"
        p={[11, 23]}
        py={[9, 9]}
        pr={[6, 18]}
        alignItems={["flex-start", "center"]}
        height="100%"
        flexDirection={["column", "row"]}
      >
        <Stack width="100%" gap={4} mb={[12, 0]}>
          <Text
            variant="subtitle1"
            maxWidth={350}
            sx={{ svg: { verticalAlign: "middle" } }}
          >
            Use these filters to choose shareholders from Say&apos;s network
            {/* display tooltip if feature is disabled */}
            {!isFilterDataAvailable && !isFeaturesLoading && (
              <>
                &nbsp;&nbsp;
                <TooltipInfoIcon
                  iconColor={COLORS.ERROR_DARK}
                  title="Example Say shareholder count and filtering"
                />
              </>
            )}
          </Text>

          <RecipientFilters isLoading={isLoading} />
        </Stack>
        <Stack width="100%" alignItems={["flex-start", "center"]} px={[0, 5]}>
          <Stack
            data-testid="message-filter-stats"
            width={isStatsLoading ? "60%" : "auto"}
          >
            <Text variant="h4">
              {isStatsLoading
                ? undefined
                : `${nFormatter(numShareholders)} shareholder${pluralize(
                    "",
                    "s",
                    numShareholders
                  )} selected`}
            </Text>
            <Text mt={3} variant="body1">
              {isStatsLoading
                ? undefined
                : `${nFormatter(sharesRepresented)} share${pluralize(
                    "",
                    "s",
                    sharesRepresented
                  )} represented`}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default ChooseRecipients;
