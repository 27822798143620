import { useNavigate, useParams } from "react-router-dom";
import { useGetProxyEventDetailsQuery } from "../../../../../../../../../store/proxyEvent";
import {
  COLORS,
  DATE_FORMAT,
  CheckCircleSvg,
  IDate,
  INPUT_TIME_FORMAT,
  nFormatter,
} from "@asayinc/component-library";
import dayjs from "dayjs";
import { TimelineInfluenceNode } from "../../Molecules";
import { useMemo } from "react";
import { getMeetingName } from "../../../../../../../utils";
import { useGetFeaturesQuery } from "../../../../../../../../../store/settings";

export const useGetTimelineData = () => {
  const navigate = useNavigate();
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data, isLoading } = useGetProxyEventDetailsQuery(proxyEventId);
  const { data: features } = useGetFeaturesQuery();
  const isFeatureEnabled = !!features?.messages;

  const totalCustomers = data?.proxyEventStats?.totalCustomers;
  const shareholderCount = totalCustomers ? nFormatter(totalCustomers) : "";

  const parsedDates = useMemo(() => {
    // get dates needed to make timeline
    const recordDate = data?.recordDate;
    const initialMailing = data?.approvedAt;
    const proxyCutoff = data?.voteCutoff;
    const meetingDate = data?.meeting?.meetingDate;

    const influenceNode = [];
    // only show vote solicitation if data is not loading and either
    // 1. no solicitation has been sent, or
    // 2. the most recent solicitation is 7+ days ago
    if (
      isLoading ||
      (data?.voteSolicitations && data?.voteSolicitations?.length > 1)
    ) {
    } else if (!data?.voteSolicitations?.length) {
      influenceNode.push({
        node: (
          <TimelineInfluenceNode
            onClick={() => navigate(`/vote/${proxyEventId}/create`)}
            buttonLabel={isFeatureEnabled ? "Send message" : "Try for free"}
            shareholderCount={shareholderCount}
          />
        ),
      });
    } else if (
      dayjs(data.voteSolicitations[0].scheduledSendAt)
        .add(7, "day")
        .isBefore(dayjs())
    ) {
      influenceNode.push({
        node: (
          <TimelineInfluenceNode
            reminder
            onClick={() => navigate(`/vote/${proxyEventId}/create`)}
            buttonLabel={isFeatureEnabled ? "Send message" : "Try for free"}
            shareholderCount={shareholderCount}
          />
        ),
      });
    }
    const meetingType = data?.meeting?.type || "";

    // combine and sort additional mailings and proxy solicitations
    const additionalMailingsAndMessages = [
      ...(data?.additionalMailings?.map((additional, idx) => ({
        title: `Additional mailing ${idx + 1}`,
        date: additional.approvedAt,
        dateFormat: DATE_FORMAT,
      })) || []),
      ...(data?.voteSolicitations?.map((solic, idx) => ({
        title: `Voting message ${idx + 1}`,
        date: solic.scheduledSendAt,
        dateFormat: DATE_FORMAT,
      })) || []),
    ].sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1));

    // set base timeline with dates and order
    const dates = [
      {
        title: isLoading ? undefined : "Record date",
        date: recordDate,
        dateFormat: DATE_FORMAT,
      },
      {
        title: isLoading ? undefined : "Initial mailing",
        date: initialMailing,
        dateFormat: DATE_FORMAT,
      },
      ...additionalMailingsAndMessages,
      ...influenceNode,
      {
        title: isLoading ? undefined : "Proxy voting cutoff",
        date: proxyCutoff,
        dateFormat: `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`,
      },
      {
        title: isLoading ? undefined : getMeetingName(meetingType, meetingDate),
        date: meetingDate,
        dateFormat: `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`,
      },
    ] as IDate[];

    // set which dates are disabled
    const datesWithDisabled = dates.map((itm) => {
      const disabled =
        isLoading || !itm.date || dayjs(itm.date).isAfter(dayjs());

      return {
        ...itm,
        disabled,
        circleIconColor: disabled ? COLORS.P_DISABLED : COLORS.SAY_COBALT,
        icon: !disabled ? <CheckCircleSvg /> : undefined,
      };
    });

    // set line colors based on n+1 and n+2 dates that are disabled
    return datesWithDisabled.map((itm, idx) => {
      // skip over node items
      const nextItem = datesWithDisabled?.[idx + 1]?.node ? 2 : 1;
      return {
        ...itm,
        date: isLoading ? undefined : itm.date,
        lineColor: datesWithDisabled?.[idx + nextItem]?.disabled
          ? COLORS.P_DISABLED
          : COLORS.SAY_COBALT,
      };
    });
  }, [data]);

  return {
    dates: parsedDates,
    isLoading,
  };
};
