import {
  IProxyEventDetails,
  IProxyEventDetailsResponse,
  IProxyProposal,
  IProxyTimelineStat,
  IProxyMessageStats,
} from "./types";
import { rootApi } from "../rootApi";

export const proxyEventApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getProxyTimelineTrend: builder.query<IProxyTimelineStat[], string>({
      query: (proxyId) => `proxy-events/${proxyId}/date-stats/`,
    }),
    /**
     * get proxy event details and transform the response to group proposals by group number
     */
    getProxyEventDetails: builder.query<IProxyEventDetails, string>({
      query: (proxyId) => `/proxy-events/${proxyId}/?details=True`,
      transformResponse(
        baseQueryReturnValue: IProxyEventDetailsResponse,
        _meta,
        _arg
      ) {
        const transformedData: IProxyProposal[][] = Object.values(
          baseQueryReturnValue.proposals.reduce((result, obj) => {
            (result[obj.groupNumber] || (result[obj.groupNumber] = [])).push(
              obj
            );
            return result;
          }, {})
        );
        return {
          ...baseQueryReturnValue,
          proposals: transformedData,
        };
      },
    }),
    /**
     * get proxy event details and transform the response to group proposals by group number
     */
    getProxyMessageStats: builder.query<IProxyMessageStats, string>({
      query: (proxyId) => `proxy-events/${proxyId}/message-stats/`,
    }),
    /**
     * Export a Vote data
     */
    exportVoteData: builder.mutation<string, string>({
      queryFn: async (proxyEventId, _api, _extraOptions, baseQuery) => {
        try {
          const result = await baseQuery({
            url: `proxy-events/${proxyEventId}/stats/`,
            headers: {
              Accept: "text/csv",
            },
            responseHandler: (response) => response.text(),
          });
          if (result.error) {
            throw new Error();
          }

          const blob = new Blob([result.data as BlobPart], {
            type: "application/octet-stream",
          });
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `vote-${proxyEventId}.csv`);
          link.setAttribute("data-testid", "csv-download-link");
          document.body.appendChild(link);
          // cant run in jest
          /* istanbul ignore next */
          if (href) {
            link.click();
          }
          return {
            data: "success",
          };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: "Failed to parse CSV data" },
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetProxyTimelineTrendQuery,
  useGetProxyEventDetailsQuery,
  useExportVoteDataMutation,
  useGetProxyMessageStatsQuery,
} = proxyEventApi;
