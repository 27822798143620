import { useCallback, useContext, useState } from "react";
import {
  useGetShareholderQuestionsUpvotedQuery,
  useGetShareholderStatsQuery,
} from "../../../../../../../../../../store/shareholder";
import QuestionList from "../QuestionList";
import { Context } from "../../../../../../context";
import {
  IShareholderQuestionsData,
  IShareholderStats,
} from "../../../../../../../../../../types/Shareholder";

const UserQuestions = () => {
  const { data } = useContext(Context);
  const [offset, setOffset] = useState(0);
  const globalUserId = data?.globalUserId;
  const { data: questionsData, isLoading } =
    useGetShareholderQuestionsUpvotedQuery({
      globalUserId: globalUserId as string,
      offset,
      includeOwnQuestions: true,
    });
  const { data: statsData = {} } = useGetShareholderStatsQuery(
    globalUserId as string
  );

  const { numQuestionsAsked = 0, numUpvotes = 0 } =
    statsData as IShareholderStats;
  const count = numQuestionsAsked + numUpvotes;

  /* istanbul ignore next */
  const getMoreItems = useCallback(() => {
    // limit of 1 is hardcoded in API request
    setOffset((offset) => offset + 1);
  }, []);

  return (
    <div data-testid="user-questions">
      <QuestionList
        count={count}
        isLoading={isLoading}
        hasMore={!!questionsData?.next}
        questionData={(questionsData as IShareholderQuestionsData).results}
        getMoreItems={getMoreItems}
        emptyHeader="No questions upvoted"
        emptySubHeader="This shareholder has not upvoted any questions yet."
      />
    </div>
  );
};

export default UserQuestions;
