import { pluralize } from "@asayinc/component-library";
import { IURL_PARAMS } from "../../types/Common";
import { QuestionsApiResponse } from "./types";
import { GetExportOptions, IQuestionsAggregate } from "./types";
import { rootApi } from "../rootApi";

// Define a service using a base URL and expected endpoints
export const questionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get QUESTIONS LIST
    getQuestions: builder.query<
      QuestionsApiResponse | IQuestionsAggregate,
      {
        eventSlug: string;
        params: IURL_PARAMS;
      }
    >({
      query: ({ eventSlug, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return `qa-events/${eventSlug}/questions/?${queryString}`;
      },
      transformResponse: (
        response: QuestionsApiResponse | IQuestionsAggregate,
        _,
        arg
      ) => {
        if (arg?.params?.agg) {
          return response as IQuestionsAggregate;
        }
        const questionsData = response as QuestionsApiResponse;
        return {
          ...questionsData,
          ids: questionsData.results.map(({ id }) => id),
        };
      },
      providesTags: ["Questions"],
    }),
    /**
     * Export a set of questions
     */
    exportQuestions: builder.mutation<{ message: string }, GetExportOptions>({
      queryFn: async (
        { eventSlug, questionIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        try {
          const result = await baseQuery({
            url: `qa-events/${eventSlug}/questions/?id__in=${questionIds.join(
              ","
            )}`,
            headers: {
              Accept: "text/csv",
            },
            responseHandler: (response) => response.text(),
          });

          const blob = new Blob([result.data as BlobPart], {
            type: "application/octet-stream",
          });
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `say-questions-${eventSlug}.csv`);
          document.body.appendChild(link);
          link.click();
          return {
            data: {
              message: `${questionIds.length} ${pluralize(
                "question",
                "questions",
                questionIds.length
              )} exported!`,
            },
          };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: "Failed to parse CSV data" },
            },
          };
        }
      },
    }),
  }),
});

export const { useGetQuestionsQuery, useExportQuestionsMutation } =
  questionsApi;
