import { Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import {
  CardWrapper,
  Carousel,
  ShareholderFeedbackSummaryCard,
} from "../../../../../../../components/Common";
import { SwiperSlide } from "swiper/react";
import {
  useGetEventFeedbackQuery,
  useGetEventSummaryQuery,
} from "../../../../../../../store/eventStats/api";
import { URL_PARAMS } from "../../../../../../../constants";
import { ShareholderFeedbackCard } from "../../Molecules";
import { initialSummaryState } from "../../../../../../../store/eventStats/initialState";
import { Link } from "react-router-dom";

interface IProps {
  slug: string;
}

const ShareholderFeedback = ({ slug }: IProps) => {
  const {
    data: eventSummaryData = initialSummaryState,
    isLoading: isSummaryLoading,
  } = useGetEventSummaryQuery(slug);
  const { data, isLoading: isFeedbackLoading } = useGetEventFeedbackQuery({
    eventSlug: slug,
    params: { [URL_PARAMS.includesReply]: "true", [URL_PARAMS.limit]: "3" },
  });

  return (
    <Stack gap={6}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Text variant="subtitle1">Shareholder feedback</Text>
        <Link
          to={`/qa/${slug}/feedback`}
          style={{ marginLeft: "auto", textDecoration: "none" }}
        >
          <Text variant="subtitle2" shade="say">
            See all
          </Text>
        </Link>
      </Stack>
      <Carousel slidesPerClick={1} isLoading={isFeedbackLoading}>
        <>
          <SwiperSlide key="overview-card">
            <CardWrapper
              cardName="shareholder-feedback-overview"
              sx={{
                maxWidth: 282,
              }}
            >
              <ShareholderFeedbackSummaryCard
                csat={eventSummaryData.csat}
                neutralPlus={eventSummaryData.neutralPlus}
                replies={eventSummaryData.replies}
                sent={eventSummaryData.feedbackSent}
                isLoading={isSummaryLoading || isFeedbackLoading}
                inCarousel
              />
            </CardWrapper>
          </SwiperSlide>
          {data?.results?.length || isFeedbackLoading ? (
            data?.results?.map((feedback) => (
              <SwiperSlide key={feedback.id}>
                <ShareholderFeedbackCard {...feedback} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide key="empty box">
              <CardWrapper
                cardName="shareholder-feedback-empty"
                sx={{
                  width: 376,
                }}
              >
                <Stack height={296} alignItems="center" justifyContent="center">
                  <Text emphasis="medium" variant="body3">
                    No free-text responses yet
                  </Text>
                </Stack>
              </CardWrapper>
            </SwiperSlide>
          )}
        </>
      </Carousel>
    </Stack>
  );
};

export default ShareholderFeedback;
