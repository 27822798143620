import { getIssuerAuthToken } from "@asayinc/component-library";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";

interface IJWT {
  is_superuser: boolean;
  groups: string[];
}

/**
 * check if current logged in user is staff or super user
 */
export const useIsSuperUser = () => {
  const authToken = getIssuerAuthToken();
  const [isSuperUser, setIsSuperUser] = useState(false);

  useEffect(() => {
    if (authToken) {
      const decodedJWT: IJWT = jwtDecode(authToken);
      setIsSuperUser(
        decodedJWT.is_superuser || decodedJWT.groups.includes("consumer_staff")
      );
    } else {
      setIsSuperUser(false);
    }
  }, [authToken]);

  return isSuperUser;
};
