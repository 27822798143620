import { useState, useCallback, useMemo } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
// components
import { Stack } from "@mui/material";
import { ArrowBack, FilterList as FilterListIcon } from "@mui/icons-material";
import {
  pluralize,
  TableV2 as Table,
  Text,
  IconButton,
} from "@asayinc/component-library";
import { ExportTopics, FilterList } from "./components";
// constants
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
} from "../../../../constants";
import { COLUMNS } from "./constants";
// utils
import { useTableSearchParams } from "../../../../hooks";
import { track } from "../../../../analytics";
import {
  trackOrderingChange,
  trackToggleAllRows,
  trackOpenDialog,
  getNoResultsProps,
} from "../../utils";
import { getColumnData, getRowData } from "./factories";
import { useCurrentlySelectedPageIds } from "../../../../hooks/useCurrentlySelectedPageIds";
// types
import { TableEventData } from "../../../../types/Table";
// redux / data
import { useGetSentimentQuery } from "../../../../store/sentiment";
import {
  useGetEventQuery,
  initialState as initialEventData,
} from "../../../../store/event";
// TODO; add errors in when API is ready
// import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";

const initialState = {
  results: [],
  count: 0,
  next: null,
  previous: null,
  ids: [],
};

const Topics = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { search } = useLocation();
  const { eventSlug } = useParams() as { eventSlug: string };

  /**
   * callback for tracking when sort occurs
   */
  const sortCallback = (sortValue: string) => {
    trackOrderingChange(sortValue, rawApiObject, eventSlug);
  };

  const {
    paramObj,
    rawApiObject,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    sortCallback,
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.sharesOwned}`,
  });

  const apiParams = {
    limit: paramObj.limit,
    page: paramObj.page,
    offset: String((parseInt(paramObj.page) - 1) * parseInt(paramObj.limit)),
    filter: paramObj.filter,
    ordering: paramObj.ordering,
    specific_category_ids: paramObj.specificCategoryIds,
  };

  const { data: eventData = initialEventData } = useGetEventQuery(eventSlug);
  const { title } = eventData;

  const {
    data = initialState,
    isFetching,
    isLoading,
  } = useGetSentimentQuery({
    eventSlug,
    params: apiParams,
  });
  const { results: topics, ids, count } = data;

  // selected on page and current page result ids
  const selectedIdsOnPage = useCurrentlySelectedPageIds(ids, selectedIds);
  // controls which dialog to display
  const [dialog, setDialog] = useState<"download" | "">("");

  /**
   * select all rows
   */
  const handleToggleAllRows = () => {
    if (selectedIdsOnPage.length) {
      setSelectedIds(
        selectedIds.filter((id) => !selectedIdsOnPage.includes(id))
      );
      trackToggleAllRows("deselect", apiParams, eventSlug);
    } else {
      setSelectedIds([...selectedIds, ...ids]);
      trackToggleAllRows("select", apiParams, eventSlug);
    }
  };

  /**
   * open topic drawer
   * @param data TableEventData
   */
  const goToTopic = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      searchParams.set(URL_PARAMS.tid, id);
      searchParams.set(URL_PARAMS.eid, eventSlug);
      setSearchParams(searchParams);
    },
    [search]
  );

  /**
   * Select row
   * add/remove from list of selected rows
   */
  const checkRow = useCallback(
    (data: unknown) => {
      const { id, checked } = data as TableEventData;
      setSelectedIds((curIds) => {
        if (curIds.includes(id)) {
          return curIds.filter((itm) => id !== itm);
        } else {
          return [...curIds, id];
        }
      });
      track({
        name: "Check Action",
        slug: eventSlug,
        topicId: id,
        sortingApplied: Boolean(apiParams.ordering),
        action: checked ? "deselect" : "select",
      });
    },
    [eventSlug, apiParams]
  );

  /**
   * Column data for table
   */
  const columnData = useMemo(
    () => getColumnData({ getSort, goToTopic, sortFn }),
    [getSort, goToTopic, sortFn]
  );

  const drawerTopicId = searchParams.get(URL_PARAMS.tid);

  /**
   * Each rows specific data for table
   */
  const rowData = getRowData({
    topics,
    selectedIds,
    checkRow,
    drawerTopicId,
  });

  /**
   * Toggle the dialog for exporting or tagging questions in bulk
   */
  const closeDialog = (
    type?: "download",
    _?: React.MouseEvent<HTMLElement>,
    _2?: unknown,
    didSave?: boolean
  ) => {
    setDialog("");
    if (type === "download") {
      track({
        name: "Export Modal Action",
        action: didSave ? "Export" : "Cancel",
        slug: eventSlug,
        totalItems: !!selectedIdsOnPage.length,
      });
    }
    if (didSave) {
      setSelectedIds(
        selectedIds.filter((id) => !selectedIdsOnPage.includes(id))
      );
    }
  };

  /**
   * Toggle the dialog for exporting or tagging quesitons
   */
  const openDialog = (type: "download") => {
    setDialog(type);
    trackOpenDialog(type, apiParams, eventSlug, "Topics List");
  };

  const noResultsData = getNoResultsProps(apiParams);

  const downloadProps = {
    active: !!selectedIdsOnPage.length,
    action: () => openDialog("download"),
    tooltip: "Export to CSV",
  };

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count,
    page: parseInt(apiParams.page as string),
    rowsPerPage: parseInt(apiParams.limit as string),
    rowOptions: ROW_OPTIONS,
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/qa/${eventSlug}/overview`);
  };

  // toolbar dropdown filter list
  const collapseContent = <FilterList />;

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  return (
    <Stack
      p={10}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      data-testid="event-topics-page"
    >
      <Stack mb={3} alignItems="flex-start">
        <Stack
          direction="row"
          onClick={goBack}
          alignItems="center"
          data-testid="go-back-cta"
          sx={{ cursor: "pointer" }}
        >
          <IconButton
            sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
          >
            <ArrowBack />
          </IconButton>
          <Text variant="subtitle2">{title}</Text>
        </Stack>
      </Stack>
      <Text variant="h5" sx={{ mb: 8 }}>
        Trending topics
      </Text>
      <ExportTopics
        open={dialog === "download"}
        handleClose={closeDialog}
        topicIds={selectedIdsOnPage}
      />
      <Table
        isFetching={isFetching}
        isLoading={isLoading}
        rows={rowData}
        memoCells
        toolbarInside
        columnData={columnData}
        toolbarCollapse={toolbarCollapse}
        tableMinWidth={1198}
        testid="questions-table"
        tableLayout="auto"
        selectedTitle={pluralize("Topic", "Topics", selectedIdsOnPage.length)}
        title="All topics"
        columns={COLUMNS}
        downloadProps={downloadProps}
        paginateProps={paginateProps}
        count={count}
        noResultsData={noResultsData}
        toggleAll={handleToggleAllRows}
        numChecked={selectedIdsOnPage.length}
      />
    </Stack>
  );
};

export default Topics;
