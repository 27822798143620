import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Stack } from "@mui/material";
import { StatCard } from "../../../../../../../components/Common";
import { nFormatter, SayIcon, Text } from "@asayinc/component-library";
// redux / data
import {
  useGetEventSummaryQuery,
  initialSummaryState,
} from "../../../../../../../store/eventStats";

const minWidth = "130px";
const width = "calc(25% - 24px)";

const Summary = () => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data = initialSummaryState } = useGetEventSummaryQuery(
    eventSlug ? eventSlug : skipToken
  );
  const {
    totalShareholders,
    totalShares,
    questionsAsked,
    questionsAuthorShares,
    questionsAnsweredCount,
    questionsAnsweredAuthorShares,
    uniqueViews,
    sharesViewed,
  } = data;

  return (
    <Stack gap={6} mb={8} data-testid="event-summary">
      <Text variant="subtitle1">Summary</Text>
      <Stack
        direction="row"
        gap={8}
        flexWrap={["wrap", "nowrap"]}
        sx={{
          // CSS Quantity queries to make sure that when the flex row wraps,
          // each row has a minimum of two items
          "> div:first-of-type:nth-last-of-type(3), > div:first-of-type:nth-last-of-type(3) ~ div":
            {
              flexBasis: ["25%", "auto"],
            },
          "> div:first-of-type:nth-last-of-type(4), > div:first-of-type:nth-last-of-type(4) ~ div":
            {
              flexBasis: ["44%", "auto"],
            },
        }}
      >
        <StatCard
          title="Views"
          minWidth={minWidth}
          width={width}
          icon={<SayIcon name="LeaderboardFilled" />}
          singleStat={{
            value: nFormatter(uniqueViews),
            subStat: `${nFormatter(sharesViewed)} shares represented`,
          }}
        />
        <StatCard
          title="Participants"
          minWidth={minWidth}
          width={width}
          icon={<SayIcon name="UserFilled" />}
          singleStat={{
            value: nFormatter(totalShareholders),
            subStat: `${nFormatter(totalShares)} shares represented`,
          }}
        />
        <StatCard
          title="Questions"
          minWidth={minWidth}
          width={width}
          icon={<SayIcon name="QuestionsFilled" />}
          singleStat={{
            value: nFormatter(questionsAsked),
            subStat: `${nFormatter(questionsAuthorShares)} shares represented`,
          }}
        />
        <StatCard
          title="Answers"
          minWidth={minWidth}
          width={width}
          icon={<SayIcon name="ChatFilled" />}
          singleStat={{
            value: nFormatter(questionsAnsweredCount),
            subStat: `${nFormatter(
              questionsAnsweredAuthorShares
            )} shares represented`,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
