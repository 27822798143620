import { Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { IPushNotificationTemplate } from "../../../../../../../store/messageLists";

interface IProps {
  notification?: IPushNotificationTemplate;
  companyName?: string;
  isStacked?: boolean;
}

const HomeScreen = ({ notification, companyName, isStacked }: IProps) => {
  const notificationTitle =
    notification?.title || `Shareholder Vote Message from ${companyName}.`;
  const notificationText =
    notification?.text ||
    `${companyName} encourages your vote on key company decisions for their upcoming meeting. Tap to get started.`;
  return (
    <Box
      width={isStacked ? 375 : 248}
      height={isStacked ? 812 : 537}
      position="relative"
      sx={{
        backgroundImage: `url('${window.config.REACT_APP_ASSETS}images/ir-portal/ios-home-screen.jpg')`,
        backgroundSize: "contain",
      }}
    >
      <Stack position="absolute" top="30%" alignItems="center" width="100%">
        <Box
          bgcolor="rgba(245, 245, 245, 0.6)"
          py={isStacked ? 2.5 : 1.5}
          px={isStacked ? 3 : 2}
          borderRadius={2}
          width={isStacked ? 359 : 230}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            mb={isStacked ? 2 : 1}
          >
            <Text
              variant="body3"
              fontSize={isStacked ? 13 : 9}
              lineHeight={isStacked ? "16px" : "12.5px"}
              sx={{ color: "rgba(63, 63, 63, 1)" }}
            >
              MOBILE APP
            </Text>
            <Text
              variant="body3"
              fontSize={isStacked ? 13 : 9}
              lineHeight={isStacked ? "16px" : "12.5px"}
              sx={{ color: "rgba(63, 63, 63, 1)" }}
            >
              Now
            </Text>
          </Stack>
          <Text
            variant="subtitle3"
            component="p"
            fontSize={isStacked ? 15 : 10}
            lineHeight={isStacked ? "20px" : "14.5px"}
            mb={isStacked ? 0.5 : 0}
          >
            {notificationTitle}
          </Text>
          <Text
            variant="body3"
            fontSize={isStacked ? 15 : 10}
            lineHeight={isStacked ? "20px" : "14.5px"}
          >
            {notificationText}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default HomeScreen;
