import { useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import {
  RangeFilter,
  AutocompleteFilter,
  CountryStateFilter,
} from "../../../../../../../components/Common";
// data
import { useGetSettingsQuery } from "../../../../../../../store/settings";
// types
import { ISettings } from "../../../../../../../store/settings/types";
// constants
import { URL_PARAMS } from "../../../../../../../constants";

const FilterList = (): JSX.Element => {
  const methods = useForm({ criteriaMode: "all" });

  const { data: settings = {}, isLoading: isSettingsLoading } =
    useGetSettingsQuery();
  const { securities = [] } = settings as ISettings;
  const hasMultipleSecurities = securities.length > 1;

  const tickerOptions = useMemo(() => {
    return securities.map(({ symbol }) => ({
      name: symbol,
      value: symbol,
      id: symbol,
    }));
  }, [securities]);

  return (
    <Stack direction="row" justifyContent="flex-end" gap={4} width="100%">
      <FormProvider {...methods}>
        <RangeFilter
          disabled={process.env.REACT_APP_ENV === "sandbox"}
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesOwnedMin}
          maxParam={URL_PARAMS.sharesOwnedMax}
          chipLabel="Shares owned"
        />
        {hasMultipleSecurities && (
          <AutocompleteFilter
            disabled={process.env.REACT_APP_ENV === "sandbox"}
            filterParam={URL_PARAMS.ticker}
            options={tickerOptions}
            fieldName="ticker"
            chipLabel="Ticker"
            groupLabel="Ticker"
            isLoading={isSettingsLoading}
          />
        )}
        <CountryStateFilter
          updateUrl
          disabled={process.env.REACT_APP_ENV === "sandbox"}
        />
      </FormProvider>
    </Stack>
  );
};

export default FilterList;
