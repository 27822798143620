import {
  ColumnType,
  DATE_FORMAT,
  nFormatter,
  Row,
  Tooltip,
} from "@asayinc/component-library";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// utils
import { formatAsPercentage } from "../../../../../../../../../utils";
// components
import { StatusBadge } from "../../../../../../../../../components/Common";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
// types
import { IMessageListItem } from "../../../../../../../../../store/messageLists";
import { MessageStatus } from "../../../../../../../../../types/Messages";
import { SORT } from "../../../../../../../../../constants";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * Column data for table
 */
export const getColumnData = ({
  goToMessage,
  getSort,
  sortFn,
}: {
  goToMessage: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}): {
  campaignName: ColumnType;
  messageDeliveryStatus: ColumnType;
  scheduledSendAt: ColumnType;
  deliveredCount: ColumnType;
  votedCount: ColumnType;
  gained: ColumnType;
} => ({
  campaignName: {
    title: "Internal name",
    style: "text",
    align: "left",
    clickHandler: goToMessage,
    sort: getSort(SORT.campaignName),
    sortFn,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  },
  messageDeliveryStatus: {
    title: "Status",
    style: "text",
    align: "left",
    clickHandler: goToMessage,
  },
  scheduledSendAt: {
    title: "Sent date",
    style: "text",
    align: "left",
    clickHandler: goToMessage,
    sort: getSort(SORT.scheduledSendAt),
    sortFn,
  },
  deliveredCount: {
    title: "Reached",
    style: "text",
    align: "left",
    clickHandler: goToMessage,
    sort: getSort(SORT.deliveredCount),
    sortFn,
  },
  votedCount: {
    title: "Voted",
    style: "text",
    align: "left",
    clickHandler: goToMessage,
    sort: getSort(SORT.votedCount),
    sortFn,
  },
  gained: {
    title: "Gained",
    style: "text",
    clickHandler: goToMessage,
    align: "left",
  },
});

export interface CreateSentMessageRowData extends IMessageListItem {
  data: {
    id: string;
    idx: number;
  };
}

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */

const getStatusContent = (status: MessageStatus): JSX.Element => {
  if (status === MessageStatus.Sent) {
    return (
      <StatusBadge messageStatus={status} sx={{ display: "inline-flex" }} />
    );
  }
  // Sending content
  return (
    <Tooltip
      placement="top"
      title="Your message is still being sent out to all selected shareholders. This may take up to a few hours."
      sx={{ width: "20px" }}
      wrap={false}
    >
      <AccessTimeIcon sx={{ fontSize: "20px" }} />
    </Tooltip>
  );
};

export const createRowData = ({
  campaignName,
  status,
  scheduledSendAt,
  stats: { deliveredCount, votedCount, gainedPct },
  id,
  data,
}: CreateSentMessageRowData): Row => ({
  rowData: {
    campaignName: { content: campaignName },
    messageDeliveryStatus: {
      asHTML: true,
      content: getStatusContent(status) as unknown as string,
    },
    scheduledSendAt: {
      content: dayjs.utc(scheduledSendAt).local().format(DATE_FORMAT),
    },
    deliveredCount: {
      content:
        deliveredCount && deliveredCount > 0 ? nFormatter(deliveredCount) : "-",
    },
    votedCount: {
      content: votedCount && votedCount > 0 ? nFormatter(votedCount) : "-",
    },
    gained: { content: gainedPct ? formatAsPercentage(gainedPct) : "-" },
  },
  data,
  id,
});

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  messages,
}: {
  messages: IMessageListItem[];
}): Row[] =>
  messages.map((message, idx) =>
    createRowData({
      ...message,
      data: {
        id: message.id,
        idx,
      },
    })
  );
