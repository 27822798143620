import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { settingsApi } from "../settings";
import { questionsApi } from "../questions";
import { questionApi } from "../question";
import { questionTagsApi } from "../questionTags";
import { ISnackBar } from "./types";
import { initialState } from "./initialState";
import { shareholderTagsApi } from "../shareholderTags";
import { ssoUserApi } from "../ssoUser";
import { companyLeadershipApi } from "../companyLeadership";
import { messageStatsApi } from "../messageStats";

// TODO - Look into catch all vs listing them all out like this - has to be a better way
const genericRejected = isAnyOf(
  questionApi.endpoints.getQuestion.matchRejected,
  questionsApi.endpoints.getQuestions.matchRejected,
  questionApi.endpoints.patchQuestion.matchRejected,
  questionApi.endpoints.patchNotes.matchRejected,
  questionApi.endpoints.removeQuestion.matchRejected,
  questionsApi.endpoints.exportQuestions.matchRejected,
  questionTagsApi.endpoints.getQuestionTags.matchRejected,
  questionTagsApi.endpoints.createQuestionTag.matchRejected,
  questionTagsApi.endpoints.bulkAddTags.matchRejected,
  shareholderTagsApi.endpoints.bulkAddShareholderTags.matchRejected,
  companyLeadershipApi.endpoints.getLeaders.matchRejected,
  companyLeadershipApi.endpoints.patchLeader.matchRejected,
  companyLeadershipApi.endpoints.postLeader.matchRejected,
  companyLeadershipApi.endpoints.deleteLeader.matchRejected,
  settingsApi.endpoints.getSettings.matchRejected,
  settingsApi.endpoints.getFeatures.matchRejected,
  settingsApi.endpoints.updateSettings.matchRejected,
  settingsApi.endpoints.updateFeatures.matchRejected
);

export const commonSlice = createSlice({
  name: "common",
  initialState,

  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.status = "loading";
      } else {
        state.status = "success";
      }
    },
    setLastPage: (state, { payload }: PayloadAction<string>) => {
      if (
        !payload.includes("/login") &&
        !payload.includes("/signup") &&
        !payload.includes("/reset-password")
      ) {
        state.prevPage = payload;
      }
    },
    clearSnackbar: (state) => {
      state.snackbar = {
        message: state.snackbar.message,
        questionId: "",
        open: false,
        type: "",
        severity: state.snackbar.severity,
      };
    },
    setSnackbar: (state, action: PayloadAction<ISnackBar>) => {
      state.snackbar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          messageStatsApi.endpoints.exportMessageData.matchFulfilled,
          messageStatsApi.endpoints.exportMessageData.matchRejected
        ),
        // RTK is not inferring type correctly
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state, action: any) => {
          const payload = action.payload;
          if (payload?.status === 500) {
            state.snackbar.open = true;
            state.snackbar.message = payload?.data?.message;
            state.snackbar.severity = "error";
          } else if (payload?.message) {
            state.snackbar.open = true;
            state.snackbar.message = payload.message;
            state.snackbar.severity = "success";
          } else {
            state.snackbar.open = true;
            state.snackbar.message = "Failed to export please try again";
            state.snackbar.severity = "error";
          }
        }
      )
      .addMatcher(
        isAnyOf(
          ssoUserApi.endpoints.submitEmail.matchRejected,
          ssoUserApi.endpoints.resetPassword.matchRejected
        ),
        (state, action) => {
          const error = action?.error;
          if (error?.message) {
            state.snackbar.open = true;
            state.snackbar.message = `${action?.payload?.status} - ${error?.message}`;
            state.snackbar.severity = "error";
          } else {
            const { data } = action?.payload as unknown as {
              data: { status: string };
            };
            const { status = "404" } = data;
            const errorStatus = action?.payload?.status || status;
            state.snackbar.open = true;
            state.snackbar.message = `${errorStatus} - Error processing your request.`;
            state.snackbar.severity = "error";
          }
        }
      )
      // RTK is not inferring type correctly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addMatcher(genericRejected, (state, action: any) => {
        const payload = action?.payload;
        if (
          payload?.error &&
          payload?.originalStatus &&
          payload?.originalStatus !== 401 &&
          payload?.originalStatus !== 403
        ) {
          state.snackbar.open = true;
          state.snackbar.message = `${payload?.originalStatus} ERROR: ${payload?.error}`;
          state.snackbar.severity = "error";
        }
      })
      .addMatcher(
        isAnyOf(
          questionTagsApi.endpoints.bulkAddTags.matchFulfilled,
          questionApi.endpoints.patchNotes.matchFulfilled,
          questionApi.endpoints.removeQuestion.matchFulfilled,
          questionApi.endpoints.patchQuestion.matchFulfilled,
          questionsApi.endpoints.exportQuestions.matchFulfilled,
          shareholderTagsApi.endpoints.bulkAddShareholderTags.matchFulfilled
        ),
        (state, action) => {
          const payload = action.payload as {
            message: string;
            snackId?: string;
            type?: "" | "goToQuestion" | "undoTag";
            idx?: number;
          };
          if (payload.message) {
            state.snackbar = {
              message: payload.message,
              questionId: payload?.snackId,
              open: true,
              type: payload?.type || "",
              idx: payload?.idx,
              severity: "success",
            };
          }
        }
      );
  },
});

export const { clearSnackbar, setSnackbar, setLoading, setLastPage } =
  commonSlice.actions;

export const commonReducer = commonSlice.reducer;
