import { useParams } from "react-router-dom";
import {
  useGetProxyEventDetailsQuery,
  useGetProxyTimelineTrendQuery,
} from "../../../../../../../../../../store/proxyEvent";
import { useMemo } from "react";
import dayjs from "dayjs";
import { IGraphData } from "../../../Organisms/ProxyDetailGraph/ProxyDetailGraph";
import {
  MailingType,
  SHORT_DATE_FORMAT,
} from "../../../../../../../../constants";

export interface IGraphDotStat {
  shares: number;
  shareholders: number;
  range: string;
  type: MailingType;
  name: string;
}

export interface IGraphDotStats {
  [key: string]: IGraphDotStat;
}

export const useGraphData = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data: timelineData = [], isLoading: isTimelineLoading } =
    useGetProxyTimelineTrendQuery(proxyEventId);

  const { data: detailsData, isLoading: isDetailsLoading } =
    useGetProxyEventDetailsQuery(proxyEventId);

  const isLoading = isTimelineLoading || isDetailsLoading;

  /** CORE LINE GRAPH DATA **/
  // parse graph data to be able to be displayed in graph
  const graphData = useMemo(() => {
    if (isLoading || !timelineData?.length) {
      return [];
    }

    // convert individual day data to cumulative, and format date name
    const chartData = timelineData.reduce((acc, cur) => {
      const prevShareholders = acc?.[acc.length - 1]?.shareholders || 0;
      const prevShares = acc?.[acc.length - 1]?.shares || 0;
      acc.push({
        shareholders: prevShareholders + (cur?.customerCount || 0),
        shares: prevShares + parseFloat(cur?.totalSharesVoted || "0"),
        name: dayjs(cur.date).format(SHORT_DATE_FORMAT),
      });

      return acc;
    }, [] as IGraphData[]);

    const meetingDate = detailsData?.meeting?.meetingDate as string;
    const lastDay = timelineData[timelineData.length - 1].date;
    // days between today and meeting date
    const daysToAdd = dayjs(meetingDate).diff(dayjs(lastDay), "day");
    // add blank days from today -> meeting date
    for (let i = 0; i < daysToAdd; i++) {
      chartData.push({
        name: dayjs(lastDay).add(i, "day").format(SHORT_DATE_FORMAT),
        shares: undefined,
        shareholders: undefined,
      });
    }

    // prepend day before voting starts for chart starting point
    const dayBeforeVotingStart = dayjs(chartData[0].name)
      .subtract(1, "day")
      .format(SHORT_DATE_FORMAT);
    chartData.unshift({
      name: dayBeforeVotingStart,
      shares: 0,
      shareholders: 0,
    });

    return chartData;
  }, [isLoading, timelineData]);

  const initialMailingShortDate = dayjs(detailsData?.approvedAt).format(
    SHORT_DATE_FORMAT
  );

  /** CONVERT ALL MAILINGS TO AN OBJECT FOR PARSING **/
  const mailings = useMemo(
    () =>
      !isLoading && detailsData
        ? {
            [initialMailingShortDate]: {
              name: "Initial mailing",
              type: MailingType.Additional,
            },
            ...detailsData?.additionalMailings?.reduce(
              (acc, cur, idx) => (
                (acc[dayjs(cur.approvedAt).format(SHORT_DATE_FORMAT)] = {
                  name: `Additional mailing ${idx + 1}`,
                  type: MailingType.Additional,
                }),
                acc
              ),
              {}
            ),
            ...detailsData?.voteSolicitations?.reduce(
              (acc, cur, idx) => (
                (acc[dayjs(cur.scheduledSendAt).format(SHORT_DATE_FORMAT)] = {
                  name: `Voting message ${idx + 1}`,
                  type: MailingType.ProxyMessage,
                }),
                acc
              ),
              {}
            ),
          }
        : {},
    [isLoading, detailsData]
  );

  /** Get the cumulative stats between each mailing **/
  const { graphMailingStats } = useMemo(
    () =>
      timelineData.reduce(
        (acc, cur) => {
          const curShareholders = cur.customerCount || 0;
          const curShares = parseFloat(cur.totalSharesVoted || "0");
          const curName = dayjs(cur.date).format(SHORT_DATE_FORMAT);
          const curNameWithYear = dayjs(cur.date).format(
            `${SHORT_DATE_FORMAT}, YYYY`
          );
          if (mailings[curName]) {
            acc.graphMailingStats[curName] = {
              shares: curShares,
              shareholders: curShareholders,
              range: `${acc.curDate} - ${curNameWithYear}`,
              type: mailings[curName].type,
              name: mailings[curName].name,
            };
            acc.curDate = curName;
          } else if (acc.graphMailingStats[acc.curDate]) {
            acc.graphMailingStats[acc.curDate].shares += curShares;
            acc.graphMailingStats[acc.curDate].shareholders +=
              curShareholders as number;
            acc.graphMailingStats[
              acc.curDate
            ].range = `${acc.curDate} - ${curNameWithYear}`;
          }

          return acc;
        },
        { graphMailingStats: {}, curDate: initialMailingShortDate } as {
          graphMailingStats: IGraphDotStats;
          curDate: string;
        }
      ),
    [graphData]
  );

  const totalShareholdersInGraph = useMemo(
    () => Math.max(...graphData.map((o) => o.shareholders || 0)),
    [graphData]
  );
  return {
    graphData,
    totalShareholdersInGraph,
    graphMailingStats,
    isLoading,
    fullTimelineData: timelineData,
  };
};
