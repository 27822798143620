import { nFormatter } from "@asayinc/component-library";

// dont see a way around any without adding types to rechart
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YAxisTick = (props: any) => {
  const { x, y, payload, color, anchor, isLoading, rightTick } = props;

  if (isLoading) {
    return (
      <g transform={`translate(${rightTick ? x - 13 : x - 20},${y - 9})`}>
        <rect width="34" height="18" rx="8" fill="#E0E0E0" />
      </g>
    );
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={anchor === "end" ? 5 : -5}
        fontSize={12}
        y={2}
        fontWeight={600}
        textAnchor={anchor}
        fill={color}
      >
        {nFormatter(payload.value)}
      </text>
    </g>
  );
};
export default YAxisTick;
