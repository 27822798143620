// dont see a way around any without adding types to rechart

import { COLORS } from "@asayinc/component-library";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const XAxisTick = (props: any) => {
  const { x, y, payload, isLoading } = props;

  if (isLoading) {
    return (
      <g transform={`translate(${x - 20},${y - 4})`}>
        <rect width="44" height="18" rx="8" fill="#E0E0E0" />
      </g>
    );
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        fontSize={12}
        y={10}
        textAnchor="end"
        fontWeight={600}
        fill={COLORS.P_MED_LOW_EMPHASIS}
      >
        {payload.value}
      </text>
    </g>
  );
};
export default XAxisTick;
