import {
  COLORS,
  Dialog,
  Text,
  getIssuerAuthToken,
} from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetUserInfoQuery,
  useTrackEventMutation,
} from "../../../../../store/consumerUser";
import FeatureOption from "./FeatureOption";
import {
  UiEventActions,
  UiEventTypes,
} from "../../../../../store/consumerUser/types";
import { useFeaturesDisabled } from "../../../../../hooks/useFeaturesDisabled";

/**
 * Dialog to display to users 1 time who have Q&A but not messages enabled
 */
const ExploreSaysFeaturesDialog = () => {
  const token = getIssuerAuthToken();
  const { isMultiFeatures, isSuccess: isFeaturesSuccess } =
    useFeaturesDisabled();
  const { data, isSuccess: isUserInfoSuccess } = useGetUserInfoQuery(
    token ? undefined : skipToken
  );
  const [trackEvent] = useTrackEventMutation();
  const [open, setOpen] = useState(false);

  // after api returns update state to open dialog
  useEffect(() => {
    // user only has q&a feature enabled
    if (
      isUserInfoSuccess &&
      isFeaturesSuccess &&
      data?.features?.portalGeneralModalUpsell &&
      isMultiFeatures
    ) {
      setOpen(true);
    }
  }, [isUserInfoSuccess, isFeaturesSuccess]);

  // close dialog immediately and hit api to disable the feature flag for displaying the dialog
  const closeDialog = () => {
    trackEvent({
      action: UiEventActions.CloseModalUpsellGeneral,
      type: UiEventTypes.Click,
      sourceUrl: location.href,
      targetUrl: null,
    });
    setOpen(false);
  };

  return (
    <Dialog
      data-testid="messages-intro-dialog"
      ctaOrientation="horizontal"
      title="Explore all of Say's features"
      titleTextVariant="h4"
      handleClose={closeDialog}
      sx={{
        ".MuiPaper-root > .MuiBox-root": {
          textAlign: "center",
        },
      }}
      content={
        <Box mt={2} mb={2} maxWidth={644}>
          <Box mb={6} textAlign="center">
            <Text variant="body1" maxWidth="546px" margin="0 auto">
              Share your company&apos;s story directly with retail shareholders
              using Messages - let shareholders know about product launches,
              partnership announcements, events and more, right to their inbox.
            </Text>
          </Box>
          <Stack gap={4} direction="row">
            <FeatureOption
              title="Shareholder network"
              icon="PeopleAltOutline"
              description="Your shareholder insights at one place"
              color={COLORS.LIGHT_GREEN}
              url="/shareholders"
              closeDialog={closeDialog}
              testId="shareholders"
            />
            <FeatureOption
              title="Messages"
              icon="MailOutline"
              description="Create differentiated storytelling to influence"
              color={COLORS.ICE_BLUE}
              url="/messages"
              testId="messages"
              closeDialog={closeDialog}
            />
            {/* TODO: enable when vote is enabled 
            <FeatureOption
              title="Voting message"
              icon="HowToVote"
              description="Get more votes through Say's voting message"
              color={COLORS.LIGHT_ORANGE}
              url="/vote"
              testId="proxy"
              closeDialog={closeDialog}
            />
      */}
          </Stack>
        </Box>
      }
      open={open}
    />
  );
};

export default ExploreSaysFeaturesDialog;
