import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
// components
import { Stack } from "@mui/material";
import { Text, ButtonV2, IconButton } from "@asayinc/component-library";
import { EventStatusOptions } from "../../../../../types/Events";
import { useAppDispatch } from "../../../../../store/hooks";
import { setSnackbar } from "../../../../../store/common";
import { StatusBadge } from "../../../../../components/Common";

interface IProps {
  eventTitle: string;
  eventSlug: string;
  status: EventStatusOptions;
  isLoading: boolean;
}

const Header = ({
  eventTitle,
  eventSlug,
  status,
  isLoading,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showEditButton = status !== EventStatusOptions.COMPLETED;
  /**
   * Route to Edit QA page
   */
  const goToEditQA = () => {
    navigate(`/qa/${eventSlug}/edit`);
  };

  /**
   * Copy URL to clipboard
   */
  const previewUrl = `${window.config.REACT_APP_QA_URL}${eventSlug}`;
  const copyUrl = () => {
    window.navigator.clipboard.writeText(previewUrl);
    dispatch(
      setSnackbar({
        message: `Link copied to clicpboard`,
        open: true,
        severity: "success",
      })
    );
  };

  // go back to last page
  const goBack = () => {
    navigate(`/qa`);
  };

  /**
   * Open preview url in a new 800x600 window
   */
  const openPreviewUrl = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.open(previewUrl, "newwindow", "width=800,height=600");
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      mb={2}
    >
      <Stack>
        <Stack mb={3} alignItems="flex-start">
          <Stack
            direction="row"
            onClick={goBack}
            alignItems="center"
            data-testid="go-back-cta"
            sx={{ cursor: "pointer" }}
          >
            <IconButton
              sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
            >
              <ArrowBack />
            </IconButton>
            <Text loadingProps={{ sx: { width: "100px" } }} variant="subtitle2">
              All Q&amp;As
            </Text>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <Text variant="h5" loadingProps={{ sx: { width: 100 } }}>
            {isLoading ? undefined : eventTitle}
          </Text>
          <StatusBadge messageStatus={isLoading ? undefined : status} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={3}>
        <ButtonV2
          data-testid="event-preview-button"
          variant="secondary"
          onClick={openPreviewUrl}
        >
          Preview
        </ButtonV2>
        <ButtonV2
          data-testid="event-copy-link-button"
          variant={showEditButton ? "secondary" : "primary"}
          onClick={copyUrl}
        >
          Copy event link
        </ButtonV2>
        {showEditButton && (
          <ButtonV2 data-testid="event-edit-button" onClick={goToEditQA}>
            Edit
          </ButtonV2>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
