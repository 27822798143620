/* istanbul ignore file */
import axios, { AxiosRequestTransformer } from "axios";
import humps from "humps";
import {
  getIssuerAuthToken,
  removeIssuerAuthToken,
  setIssuerAuthToken,
} from "@asayinc/component-library";
import jwt_decode from "jwt-decode";
import { router } from "../router";
import { TEST_WINDOW_CONFIG } from "../test-tools/constants";

interface IJWT {
  exp: number;
}

let refreshPromise: Promise<void> | null = null;

// use test configuration if node_env is test
const windowConfig =
  process.env.NODE_ENV === "test" ? TEST_WINDOW_CONFIG : window.config;

// clear local storage and auth token
const logout = () => {
  removeIssuerAuthToken();
  window.localStorage.clear();
  router.navigate("/login", { replace: true });
};

export const refreshTokenIfExpired = async () => {
  // wait for current token request if available
  if (refreshPromise) {
    await refreshPromise;
  }

  const token = getIssuerAuthToken();
  const refreshToken = window.localStorage.getItem("issuer-refresh-token");

  if (token && refreshToken) {
    const decodedJWT: IJWT = jwt_decode(token);
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const { exp } = decodedJWT;
    return new Promise(async (resolve, reject) => {
      if (nowInSeconds > exp) {
        refreshPromise = ssoApi
          .post(`/auth/refresh-token/`, {
            token: refreshToken,
          })
          .then((response) => {
            const { accessToken } = response.data;
            setIssuerAuthToken({ value: accessToken });
            refreshPromise = null;
            resolve(accessToken);
          })
          .catch((error) => {
            reject(error);
            // reset to login if refresh fails
            logout();
            refreshPromise = null;
          });
      } else {
        resolve(token);
      }
    });
  }
  // reset to login if no token & refreshtoken
  logout();
  return null;
};

/**
 * converts my_var to myVar and back for requests/responses.
 * Covers any and all responses/requests. no type needed
 */
const sharedAxiosConfiguration = {
  transformRequest: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosRequestTransformer[]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => humps.camelizeKeys(data),
  ],
};

// SSO
const ssoApi = axios.create({
  ...sharedAxiosConfiguration,
  baseURL: `${windowConfig.REACT_APP_SSO_API}v1/`,
});
// Consumer
const protectedConsumerApi = axios.create({
  ...sharedAxiosConfiguration,
  baseURL: `${windowConfig.REACT_APP_CONSUMER_API}v3/portal/`,
});

protectedConsumerApi.interceptors.request.use(
  async (config) => {
    const token = await refreshTokenIfExpired();
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${token}`,
      ...(!!window.localStorage.getItem("company-override")
        ? {
            "company-override": window.localStorage.getItem(
              "company-override"
            ) as string,
          }
        : {}),
    };
    return config;
  },
  (error) => Promise.reject(error)
);

export { ssoApi, protectedConsumerApi };
