import { DotProps } from "recharts";
import {
  IGraphDotStat,
  IGraphDotStats,
} from "../../../../Templates/ProxyDetailGraphCard/hooks/useGraphData";
import { IGraphData } from "../../ProxyDetailGraph";
import { LocalPostOfficeDot, MailDot } from "../../../../Atoms";
import { MailingType } from "../../../../../../../../../constants";

interface ICustomDot extends DotProps {
  handleMouseLeave: () => void;
  handleMouseEnter: (
    e: React.MouseEvent<SVGSVGElement>,
    stats: IGraphDotStat
  ) => void;
  payload?: IGraphData;
  graphMailingStats: IGraphDotStats;
}

const CustomizedDot = (props: ICustomDot) => {
  const {
    cx,
    cy,
    handleMouseEnter,
    handleMouseLeave,
    graphMailingStats,
    payload,
  } = props;
  // show one of two custom dots based on mailing type
  if (payload?.name && graphMailingStats?.[payload?.name]?.type) {
    switch (graphMailingStats[payload.name].type) {
      case MailingType.Additional:
      default:
        return (
          <LocalPostOfficeDot
            cx={(cx || 0) - 26}
            cy={(cy || 0) - 26}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            dotStats={graphMailingStats[payload.name]}
          />
        );
      case MailingType.ProxyMessage:
        return (
          <MailDot
            cx={(cx || 0) - 26}
            cy={(cy || 0) - 26}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            dotStats={graphMailingStats[payload.name]}
          />
        );
    }
  }
  return null;
};

export default CustomizedDot;
