import { useMemo } from "react";
import { useGetFeaturesQuery } from "../store/settings";
import { IFeatures } from "../store/settings/types";
import { getIssuerAuthToken } from "@asayinc/component-library";
import { skipToken } from "@reduxjs/toolkit/dist/query";

// hook to get list of disabled features for targeted expieriences
export const useFeaturesDisabled = () => {
  const token = getIssuerAuthToken();
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useGetFeaturesQuery(token ? undefined : skipToken);

  // convert data to array of disabled features
  const disabledFeatures = useMemo(
    () => Object.keys(data).filter((key) => !data[key as keyof IFeatures]),
    [data]
  );

  return {
    isSingleFeature: disabledFeatures.length === 1,
    isMultiFeatures: disabledFeatures.length > 1,
    disabledFeatures,
    isLoading,
    isSuccess,
  };
};
