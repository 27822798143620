import { IShareholder } from "../../../../../../../store/shareholderList/types";

export const columns: string[] = ["firstName", "shares", "location"];

export const columnsWithTicker: string[] = [
  "firstName",
  "shares",
  "ticker",
  "location",
];

const disabledFeatureUser = {
  firstName: "Jane",
  lastName: "D",
  sharesOwned: 100,
  location: "California, US",
  ticker: "ABC",
  globalShareholderId: "1",
};

export const disabledFeatureData = Array.from({ length: 10 }, (_, i) => ({
  ...disabledFeatureUser,
  globalShareholderId: `${i}`,
})) as IShareholder[];
